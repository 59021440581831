.realizations__wraper {
    margin: 2rem 0rem;
    @media screen and (min-width: 992px){
      margin-top: 3.75rem;
    }
}
.text__block{
  display: block;
}
.realizations__items {
    margin-bottom: 2rem;
}
.text__paragraph{
  text-shadow: 6px 4px 5px black;
}
.realizations__item:nth-child(odd){

.realizations__conttent {
  max-width: 41rem;
  margin: 1rem 1rem;
  border-bottom: 1px solid $orange;
  ul{
    li:before{
      content: '\f061';
      font-family: 'Font Awesome 5 Pro';
      font-weight: 900;
      font-size: 0.75rem;
      position: relative;
      top: -0.125rem;
    }
  }
    padding: 0rem 1rem;
    padding-left: 0;
    padding-right: 1rem;
    @media screen and (min-width: 768px)
    {
      margin-left: 2rem;
      margin-right: 0;
    }
    padding-bottom: 1rem;
  }
}
.realizations__item {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}
.realizations__item:nth-child(even){
	flex-direction: row-reverse;
  text-align: right;
  .realizations__conttent {
    max-width: 41rem;
    margin: 1rem 1rem;
    border-bottom: 1px solid $orange;
    ul{
      li:before{
        content: '\f061';
        font-family: 'Font Awesome 5 Pro';
        font-weight: 900;
        font-size: 0.75rem;
        position: relative;
        top: -0.125rem;
      }
    }
    padding: 0rem 1rem;

    padding-right: 0;
    padding-left: 1rem;
    padding-bottom: 1rem;
    @media screen and (min-width: 768px)
    {
      margin-right: 2rem;
      margin-left: 0;
    }
    h3{
      text-align: right;

    }
  }
}
.realizations__title h3 {
    font-size: 2.375rem;
    font-weight: 800;
    margin-bottom: 0.5rem;
    font-family: 'Oswald';
    text-transform: uppercase;
}
.realizations__image {
    text-align: center;
}
@media (pointer: coarse) and (max-width: 1200px) {

}
@media screen and (max-width: 1400px){

}
@media screen and (max-width: 991px){

}
@media screen and (max-width: 767px){

.realizations__item {
    display: block;
}

}
@media screen and (max-width: 414px){

}
@media screen and (max-width: 320px){

}
