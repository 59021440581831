.gallery_big.swiper-container:not(.swiper-container-fade){
  .swiper-slide:not(.swiper-slide-active){
    opacity: 0 !important;
  }
}
.gallery_big.swiper-container.swiper-container-fade{
  opacity: 1;
  img{
    width: 32rem;
    height: 26rem;
    object-fit: cover;
    @media screen and (max-width: 1400px){
      width: 35rem;

    }
    @media screen and (max-width: 991px){
      width: 100%;
      height: 20rem;
    }
    @media screen and (max-width: 399px){
      height: 14rem;
    }
  }
  body.noTouchscreen & a:hover{
    opacity: 1;
  }
}

.gallery_small{
  // margin-left: 0.5rem;
  // margin-right: 0.5rem;
  margin-top: 1rem;
  position: relative;
  height: 4rem;

  & .swiper-container:not(.swiper-container-free-mode){
    display: none;
  }

  body.noTouchscreen & a:hover{
    opacity: 1;
  }
  // margin-left: 2rem;
  // margin-right: 2rem;
  .small-button-next{
    margin-right: 0rem;
    right: 0rem;
    @media screen and (max-width: 991px){
      // margin-right: 0;
    }
  }
  .big-image{
    cursor: pointer;
    border: 2px solid transparent;
    &.-clicked{
      border: 2px solid $orange;
    }
    img{
      width: 7em;
      height: 4em;
      object-fit: cover;
      @media screen and (max-width: 1400px){
        width: 9em;
        height: 5em;
        max-width: 100%;
      }
      @media screen and (min-width: 767px) and (max-width: 991px){
        width: 9.5em;
        height: 5em;

      }
    }
  }
  .small-button-prev, .small-button-next{
    svg{
      width: 1rem;
      height: 1.25rem;
      position: relative;
      top: 0.5rem;
      left: 0.75rem;
    }

    background-color: $black;
    color: #fff;
    background-image: none;
    position: absolute;
    width: 2.375rem;
    height: 2.375rem;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    margin-top: -0.5rem;
    z-index: 10;
    transition: all 0.5s ease;
    cursor: pointer;
    svg {
      fill: $white;
      transition: 0.3s;
      #pusty{
        opacity: 0;
        transition: 0.3s;
      }
      #pelny{
        opacity: 1;
        transition: 0.3s;
      }
    }
    &.swiper-button-disabled{
      background-color: $gray;
      // opacity: 1 !important;
      cursor: auto;
      &:hover {
        // opacity: 1 !important;
      }
       svg {
        fill: $black;
        transition: 0.3s;
        #pusty{
          opacity: 1;
          transition: 0.3s;
        }
        #pelny{
          opacity: 0;
          transition: 0.3s;
        }
      }
      visibility: hidden;
    }
    opacity: 0;
    @media screen and (max-width: 991px){
      opacity: 0.6;
    }
  }
  .small-button-prev{
    margin-left: 0rem;
    left: 0;
    @media screen and (max-width: 991px){
      // margin-left: 0;

    }
    svg {
      -webkit-transform: rotate( -180deg );
        -ms-transform: rotate( -180deg );
        transform: rotate( -180deg );
        left: 0.5rem;
        position: relative;
    }
  }
}
.gallery_small:hover .small-button-prev, .gallery_small:hover .small-button-next{
  opacity: 0.7;
}
