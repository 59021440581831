.production_sliderControls {
    text-align: right;
    margin-bottom: 1rem;
}
.production_sliderImgs img {
    padding: 0.75rem;
}
.production_sliderImgs .slick-dots {
    position: absolute;
    bottom: -2.5rem;
    margin-right: 1rem;
}
// .page__bacground_animatedSvg{
//   margin-bottom: -8rem;
// }
.production_slider{
  .opinions__sliderButons {
      display: inline-block;
  }
  .opinions__sliderLink {
      display: inline-block;
      margin-right: 2rem;
  }
  .opinions__sliderControls {
      display: flex;

  }
  .opinions__slider {
      margin-bottom: 2rem;
  }
  .newsleater_btnWrap {
      text-align: center;
  }
  .opinions__sliderLink .btn-long {
      padding: 0.7rem 4rem;
  }
  .btn-slider.btn-icon {
      width: 2.9rem;
      height: 2.9rem;
      padding: 0.7rem 0.7rem;
      border-radius: 100%;
      position: relative;
  }
  .opinions__arrow svg {
      width: 1rem;
      height: 1.25rem;
      fill: $black ;
      transition: 0.3s;
      #pusty{
        opacity: 1;
        transition: 0.3s;
      }
      #pelny{
        opacity: 0;
        transition: 0.3s;
      }
  }
  .btn-icon .opinions__arrow {
      width: 0px;
      height: 0px;
      position: absolute;
      left: 1.125rem;
      top: 0.75rem;
  }
  .opinions__arrow.arrow_right {
    left: 1.125rem;
    top: 0.875rem;
  }
  .opinions__arrow svg .fil0, .opinions__arrow svg {
      fill: #151517;
  }
  .opinions__arrow.arrow_Left svg {
  	-webkit-transform: rotate( -180deg );
      -ms-transform: rotate( -180deg );
      transform: rotate( -180deg );
      left: -6px;
      top: 1px;
      position: relative;
  }
  .opinions__sliderButons .btn-slider {
      opacity: 0.2;
      transition: 0.3s;
  }
  body.noTouchscreen &{
    .opinions__sliderButons .btn-slider:hover {
        opacity: 1;
        transition: 0.3s;
    }
    .opinions__sliderButons .btn-slider:hover .opinions__arrow svg .fil0, .opinions__sliderButons .btn-slider:hover .opinions__arrow svg {
    	transition: 0.2s;
    	fill: $black;
      #pusty{
        opacity: 0;
      }
      #pelny{
        opacity: 1;
      }
    }
  }
  .opinions__title {
    font-weight: 300;
    font-size: 3rem;
    font-family: 'Oswald';
  }
  .opinions__opinion {
      /*min-height: 15rem;*/
  }
  .slick-prev {
      left: 45%;
  }
  .slick-next {
      right: 45%;
  }
  .slick-prev, .slick-next{
      top: 100%;
  }
  .slick-dots li {
      position: relative;
      cursor: pointer;
      border: 1px solid #fff;
      background: #fff;
      border-radius: 2px;
      display: inline-block;
      width: 2px;
      height: 40px;
      margin-left: 1.5rem;
      transform: rotate(-25deg);
      cursor: pointer;
  }
  .slick-dots li.slick-active{
      border-color: $orange;
      background: $orange;
  }
  .slick-dots{
      right: 0px;
      width: auto;
  }
  .slick-dots li button:before{
      display: none;
  }
  .slick-dots li button {
      transform: rotate(23deg);
      height: 2.4rem;
      top: 0rem;
      position: relative;
      left: -0.6rem;
  }
  .slick-dots {
      position: absolute;
  }

  @media screen and (max-width: 1400px){

  }
  @media screen and (max-width: 991px){
      .about__wrap {
          margin: auto;
      }
  .opinions__content {
      display: block;}
  .opinions__left,.opinions__right {
      width: 100%;
      max-width: initial;
  }
  }
  @media screen and (max-width: 767px){
  .opinions__brandName{
      font-size: 4rem;

  }
  .opinions__opinionTtile h2{
      font-size: 1.5rem;
  }
  .opinions__sliderControls {
      display: block;
  }
  .opinions__sliderLink {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
  }
  .opinions__sliderButons {
      display: block;
  }
  ul.slick-dots {
      margin-right: 15px;
  }

  }
  @media screen and (max-width: 414px){
   .opinions__brandName {
      font-size: 2rem;
  }
  .opinions__sliderControls {
      display: block;
      text-align: center;
  }
  .opinions__slider ul.slick-dots li{
      display: none;
  }
  .opinions__sliderButons .btn{
          padding: 1rem 1.2rem;
  }
  }
  @media screen and (max-width: 320px){

  }
}
