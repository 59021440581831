#backtotop{

	// position
	position: fixed;
	right: 3rem;
	bottom: 3rem;



	// visibility

	opacity: 0;
	pointer-events:none;
	&.visible{
		opacity: 1;
		pointer-events:auto;
	}

	// przykladoe style
	// background-color:black;
	color:white;
	width: 3rem;
    height: 3rem;
    text-align: center;
    font-size: 2rem;
    transition:0.3s ease;
	cursor:pointer;
	@media (max-width: 500px){
		display: none;
	}
}
