.coop__qestionsSelect:invalid
 { color: #fff; }

 .coop__qestionsTitle {
    margin-bottom: 1rem;
}
.coop__qestionsText {
    margin-bottom: 1.2rem;
}

select.coop__qestionsSelect {
    max-width: 678px;
    width: 100%;
    background: transparent;
    text-align: center;
    border: 1px solid $orange;
    padding: 1rem;
    font-size: 1rem;
    font-family: 'Oswald', sans-serif;
    color: #fff;
    margin-bottom: 1.8rem;
}
.coop__qestionsSelect option {
    color: #000;
}
.coop__qestions {
    text-align: center;
    p{
      max-width: 39rem;
      font-size: 0.875rem;
      font-weight: 500;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
}
@media screen and (max-width: 767px){
  .coop__qestionsTitle h2{
    padding-left: 2rem;
  }
}
