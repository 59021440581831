.special__app{
	display: flex;
	flex-wrap: wrap;
}

.pic-wrapper{
	    flex-grow: 1;
}



// rezultat

.pic-wrapper{
	img{
		width: 200px;
	}
}


// opcje
.special__options{
	width: 30rem;
	max-width: 100%;
}

// intro

.special__intro{
	background-color: black;

	border-top-left-radius: 0.7rem;
	border-top-right-radius: 0.7rem;
	padding: 1.5rem;
	margin-bottom: 0.4rem;

	h1,p,&{
		color: white;
	}

	h1{
		font-size: 2rem;
	}
}

// grupa opcji ogólnie

.shop-filter__options-wrapper{
	max-height: initial !important;
}


	.shop-filter__component + .shop-filter__options-wrapper{
		display: none;
	}

	.shop-filter__component.is-selected + .shop-filter__options-wrapper{
		display: block;
	}

	.special-name{
		padding-bottom: 0rem;
		h1{
			margin-bottom: 0rem;
		}
	}

// pojedyncza grupa opcji

// opcje
.shop-filter__item{
	background-color: #f6f6f6;
	margin-bottom: 0.4rem;
}

// naglowek grupy opcji

.shop-filter__component{
	height: 3.75rem;
    display: flex;
    align-items: center;
}

.shop-filter__component__icon{
	height: 3.75rem;
    width: 3.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
}

.shop-filter__component__icon img{
	height: 1.5rem;
}

.shop-filter__component__title{
	font-size: 1.125rem;
	font-weight: bold;
	font-family: Oswald;
	padding-left: 1rem;
	max-width: 9rem;
	width: 100%;
}

// .shop-filter__component:not(.is-selected){
// 	    cursor: pointer;
// }

.shop-filter__component__subtitle{
	    padding-right: 2.5rem;
	    width: 11rem;
}

// strzalka w naglowku

.shop-filter__component{
	position: relative;
}



.shop-filter__component:after{
	content: '';
	background-image: url(img/special/arrow.png);
	position: absolute;
	right: 0rem;
	top: 0rem;
	height: 3.75rem;
	width: 1.5rem;
	background-color: black;
	background-repeat: no-repeat;
	background-position: center;
	    padding-left: 1.2rem;
    padding-right: 1.2rem;
}

.shop-filter__component.is-selected:after{
	transform: rotate(180deg);
}

// lista opcji grupy opcji

.shop-filter__options-list{
	display: flex;
    // justify-content: center;
    justify-content: flex-start;
    padding: 1rem;
    flex-wrap: wrap;

    img{
    	height: 6rem;
    }
}

// pojedyncza opcja

.shop-filter__option{
	padding: 1rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    // align-items: center;
    align-items: flex-start;
    transition: 0.3s ease;
    &:hover{
    	background-color: rgba(255, 255, 255, 0.75);
    }
    position: relative;
    margin-right: 1rem;
    // margin-bottom: 1rem;
}

.shop-filter__option.is-selected{
	background-color: rgba(255, 255, 255, 0.75);
	    border: 2px solid $orange;
	&:after{
	content: '';
    width: 4rem;
    height: 4rem;
    background-image: url(img/special/check.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 2rem;
    position: absolute;
    top: 0rem;
    right: 0rem;
    display: none;
	}
}

.bc_option-text{
	// font-size: 0.8rem;
	// padding-top: 1rem;
    font-weight: 500;
}

.special__price-impact{
	    font-weight: 400;
}


// podsumowanie

.product-prices{
	.product-price{
		 padding-top: 1rem;
	}
}

.product.summary{
	background-color: black;
	border-bottom-left-radius: 0.7rem;
	border-bottom-right-radius: 0.7rem;
	    position: relative;
}

.customizer_add_to_cart_button{
	background-color: $orange;
    font-family: oswald;
    text-transform: uppercase;
    color: white;
    padding: 1rem 2rem;
    border-radius: 3rem;
    position: absolute;
    right: 2rem;
    bottom: -1.8rem;
    &:hover{
    	opacity: 1;
    }
}
