& {
    margin: 2rem 0rem;
    margin-top: 3.75rem;
    @media screen and (max-width: 767px){
      margin-top: 2rem;
    }
}
.page__subPageTop{
  margin-bottom: 2rem;
}
.opinions__items {
    margin-bottom: 2rem;
}
.opinions__content {
    width: 48rem;
    max-width: 48%;

    padding: 0rem 1rem;
    border-bottom: 1px solid $orange;
}
.opinions__item {
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
}
.opinions__text {
  // font-weight: 400;
  margin-bottom: 1.25rem;
}


.opinions__title h3 {
    font-size: 2.375rem;
    font-weight: 800;
    margin-bottom: 0.5rem;
    font-family: 'Oswald';
    font-weight: 600;
    @media (max-width: 500px){
      font-size: 1.75rem!important;
      margin-top: 0.75rem;

    }
}
.opinions__image {
    text-align: center;
}
@media (pointer: coarse) and (max-width: 1200px) {

}
@media screen and (max-width: 1400px){

}
@media screen and (max-width: 991px){
}
@media screen and (max-width: 767px){
.opinions__item {
  display: block;
  .opinions__content{
    display: block;
    max-width: 100%;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    &:nth-child(2){
      margin-top: 2rem;
    }
  }


}

}
@media screen and (max-width: 414px){

}
@media screen and (max-width: 320px){

}
