.page-not-found{
    margin-left: 0px !important;
p{
   margin-bottom: 1rem;
}

#search_widget{
  form{
    width: 30rem;
    max-width: 100%;
        display: flex;
  }
  input{
    padding: 1rem;
    border: 1px solid black;
    width: 100%;
  }
  button{
        padding-left: 1rem;
  }
}

#clear__btn{
  display: none;
}

}