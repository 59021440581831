.realizations__mapWrap {
    position: relative;
}
.realizations__mapBg {
    position: absolute;
    height: 100%;
}
.realizations__mapList{
  position: relative;
  li{
    transition: 0.3s;
    cursor: pointer;
  }
  .swiper-button-disabled{
    cursor: auto;
  }

  li:hover{
    opacity: 0.7;
  }
  .-selected{
    color: $orange;
    font-weight: 600;
  }
  ul{
    // padding-top: 3rem;
    // padding-bottom: 3rem;
  }
  .realizations__sliderButons{

    .btn-slider.btn-icon {
        width: 2.9rem;
        height: 2.9rem;
        padding: 0.7rem 0.7rem;
        border-radius: 100%;
        position: relative;
        background-color: #d7d7dd;
        opacity: 1;
    }
    .opinions__arrow svg {
        width: 1rem;
        height: 1.25rem;
        fill: white;
        transition: 0.3s;
        -webkit-transform: rotate( 90deg );
          -ms-transform: rotate( 90deg );
          transform: rotate( 90deg );
        #pusty{
          opacity: 1;
          transition: 0.3s;
        }
        #pelny{
          opacity: 0;
          transition: 0.3s;
        }
    }
    .opinions__arrow svg .fil0, .opinions__arrow svg {
        fill: white;
        top: 0.1875rem;
        position: relative;

    }
    .opinions__arrow.arrow_Left svg {
    	-webkit-transform: rotate( -90deg );
        -ms-transform: rotate( -90deg );
        transform: rotate( -90deg );
        top: -0.0625rem;
        position: relative;
    }
     .btn-slider {
        opacity: 0.2;
        transition: 0.3s;
    }
    body.noTouchscreen &{

      .btn-slider:hover {
          opacity: 1;
          transition: 0.3s;
      }
    }

    .btn-top{
      // position: absolute !important;
      // top: -3rem;
      // left: 0;
      // right: 0;
      // bottom: auto;

    }
    .btn-bot{
      // position: absolute !important;
      // top: auto;
      // left: 0;
      // right: 0;
      // bottom: -3rem;
    }
    .btn-slider{
      margin-left: 0;
      margin-right: auto;
      z-index: 10
    }
    body.noTouchscreen &{

      .btn-slider:not(.swiper-button-disabled):hover .opinions__arrow svg .fil0, .btn-slider:not(.swiper-button-disabled):hover .opinions__arrow svg {
        transition: 0.2s;
        fill: white;
        #pusty{
          opacity: 0;
        }
        #pelny{
          opacity: 1;
        }

      }
      .btn-slider:not(.swiper-button-disabled):hover{
        background-color: $black ;

      }
    }
  }
}
.realizations__mapContent {
    position: relative;
    display: flex;
    .realizations__mapMap{
      margin-top: 4rem;
      height: 46rem;
      width: 100%;
      .poi-info-window .title{
        color: $black;
        font-family: "Roboto";
        font-weight: 500;
        font-size: 14px;
        background-color: white;
        -moz-user-select: text;
        -webkit-user-select: text;
        -ms-user-select: text;
        user-select: text;
      }
      .poi-info-window .view-link, .poi-info-window a:visited, .poi-info-window a{
        font-family: "Roboto";
        font-size: 13px;
        background-color: white;
        -moz-user-select: text;
        -webkit-user-select: text;
        -ms-user-select: text;
        user-select: text;
        font-weight: 400;
        color: $orange !important;
      }
      .poi-info-window .view-link:hover, .poi-info-window a:hover {
          cursor: pointer;
          text-decoration: underline;
      }
      .poi-info-window{
        .iw-close-btn{
          color: $orange;
      		position: absolute;
      		top: -0.375rem;
      		right: 0;
      		font-size: 1.25rem;
          cursor: pointer;
          -moz-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
          z-index: 1;
        }
      }
    }
}
&{
  padding-bottom: 3rem;
}
.realizations__mapList {
  li{
    max-width: 19rem;
  }
  .mCSB_1_scrollbar{
    cursor: pointer;
    width: 20px !important;

    .mCSB_draggerRail{
      background-color: rgba(black, 0.1);
      width: 5px !important;

    }
    .mCSB_dragger{
      width: 10px;
      left: 5px;
      background-color: $orange;
      cursor: pointer;
      border-radius: 10px;
      .mCSB_dragger_bar{
        background-color: transparent;
      }
    }
  }
}
.realizations__mapList{
  .realizations__places-list{
    height: 33rem;
  }
}
.realizations__mapText h3 {
    font-size: 3rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
    font-family: 'Oswald';
}
.realizations__mapTexts {
    max-width: 30rem;
    @media screen and (min-width: 992px){
      margin-right: 5rem;
    }
}
.realizations__mapText {
    margin-bottom: 1rem;
}
.realizations__mapList li{
  // font-size: 1.5rem;
  font-size: 1.2rem;
  font-family: 'Oswald';
  text-transform: uppercase;
  margin-bottom: 0.2rem;
}
.realizations__mapWrapper{
  position: relative;
  width: 100%;
}
.map-resize-button {
  @media screen and (min-width: 992px){
    margin-top: 4rem;

  }
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    color: white;
    background-color: $orange;
    text-transform: uppercase;
    font-size: 11px;
    padding: 5px;
    border-left: 1px solid $orange;
    border-bottom: 1px solid $orange;
    letter-spacing: 1px;
    cursor: pointer;
    opacity: 0;
    transition: 0.5s;
    font-weight: 600;
    font-family: 'Oswald';
    &.-visible{
      opacity: 1;

    }
}
@media (pointer: coarse) and (max-width: 1200px) {

}
@media screen and (max-width: 1400px){

}
@media screen and (max-width: 991px){


}
@media screen and (max-width: 991px){
.realizations__mapBg {
    height: auto;
}
.realizations__mapContent {
    .realizations__mapMap{
      margin-top: 2rem;
      height: 27rem;
    }
    display: block;

}
.realizations__mapWrapper{
  margin-top: 3.5rem;
}
.realizations__mapTexts{
  max-width: 27rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.realizations__mapList{
  .realizations__places-list{
    height: 16rem;
  }
}
}
@media screen and (max-width: 524px){
  .realizations__mapContent {
      .realizations__mapMap{
        height: 14rem;
      }
    }
    .realizations__mapList{
      .realizations__places-list{

        height: 12rem;
      }

    }
}
@media screen and (max-width: 320px){

}
