#ask_popup{
  display: none;
}


#ask_popup_wrapper #ask_popup{
  display: block;
}

.question__content{
  width: 40rem;
  height: 30rem;
  background-color: white;
  @media screen and (min-width: 992px){
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
}

#ask_popup_background {
  transition: all 0.3s 0.3s;
}
#ask_popup,
#ask_popup_wrapper {
  transition: all 0.3s ease-out;
}
#ask_popup {
  transform: translateX(0) translateY(-40%);
}
.popup_visible #ask_popup {
  transform: translateX(0) translateY(0);
}
