.page__bredcrapms {
    font-family: 'Roboto', sans-serif;
    margin: 1rem 0rem;
    font-weight: 300;
    font-size: 0.9rem;
}
.page__bredcrapms li {
    display: inline-block;
    border-right: 1px solid #fff;
    margin-right: 1rem;
    padding-right: 1rem;
}
.page__bredcrapms li:last-child {
    border-right: none;
    color: $orange;
    font-weight: bold;
}
.page__bredcrapms {
    font-family: 'Roboto';
    margin: 1rem 0rem;
}
