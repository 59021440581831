$mm_menuMaxWidth : 250px !default;

@import "../lib_new/mmenu/src/core/offcanvas/jquery.mmenu.offcanvas";

@import "../lib_new/mmenu/src/core/oncanvas/jquery.mmenu.oncanvas";

@import "../lib_new/mmenu/src/extensions/positioning/jquery.mmenu.positioning";

@import "../lib_new/mmenu/src/extensions/themes/mixins";
@import "../lib_new/mmenu/src/extensions/themes/variables";

//	Dark
$mm_t_cls						: ".mm-menu_theme-dark";
$mm_t_backgroundColor			: $black;
$mm_t_textColor					: rgba( #fff, 0.85 );
$mm_t_dimmedTextColor			: rgba( #fff, 0.4 );
$mm_t_borderColor				: rgba( #000, 0.3 );
$mm_t_emphasizedBackgroundColor	: rgba( #000, 0.1 );
$mm_t_highlightedBackgroundColor: rgba( #fff, 0.08 );
$mm_t_navbarColor 				: rgba( #fff, 0.4 );
$mm_t_inputBackgroundColor		: rgba( #fff, 0.15 );
$mm_t_pageShadow				: 0 0 20px rgba( #000, 0.5 );
$mm_t_panelShadow				: 0 0 20px rgba( #000, 0.5 );

@if ( $mm_opt_themes_dark )
{
	@include mm_theme_colors;
}


.mobile_languages{
  display: flex;
  padding: 5px 10px 5px 20px;
  a{
    border: 1px solid rgba(173, 166, 166, 0.25);
    margin: 5px !important;
    display: inline-block !important;
    width: 48px;
    text-align: center;
    text-transform: uppercase;
    padding: 10px !important;
  }
}

// dwa razy uzyty widget user, raz ukryj link logout, raz ukryj link konto

.mm-listitem .account{
	    display: block;
    margin-top: 20px;

&:before {
    content: '';
    border-bottom-width: 1px;
    border-bottom-style: solid;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    border-color: rgba(0, 0, 0, 0.3);
    top: -10px;
}

}

.mm-sronly{
    display: none;
}

.about_submenu{
    .mm-panel{
            padding-top: 0px;
    padding-bottom: 0px;
        font-size: 13px;
    }
    .mm-listitem > a{
            padding-top: 7px;
    padding-bottom: 7px;
    }
}
#mmenu_mobile{
	.hidden-sm-down{
		display: inline-block !important;
	}
	i.logged{
		display: none;
	}
	@media screen and (min-width: 992px){
		display: none;
	}
}
.mm-panel{
	line-height: 1.25;
	font-size: 0.875rem;
}
.logo{

	img{
		width: 200px;
		height: 2.25rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
		position: relative;
		top: -1.5rem;
	}
}
// // fix mmenu  scroll top
// html { height: auto; }
//
.mm-menu_offcanvas.mm-menu_opened {
  display: block;
  position: fixed;
}
.mm-panels > .mm-panel > .mm-listview:first-child, .mm-panels > .mm-panel > .mm-navbar + .mm-listview{
  margin-top: -8px;
}
