& {
  padding: 0 0rem;
  position: relative;
  overflow: hidden;
  @media (max-width: 91px){
    padding-bottom: 2rem;
  }
}
.swiper-slide {
  width: 20.8125rem;
  max-width: 100%;
  margin-left: 0.375rem;
  margin-right: 0.375rem;
}
.swiper-slide {
  @media (max-width: 991px){
    height: auto;
    padding-top: 3.5rem;
    padding-bottom: 0.5rem;
    &::before{
      content: '';
      background-color: rgba(black, 0.35);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
    }

  }
  position: relative;
  width: 100%;
  @media (min-width: 992px){
    height: calc( 40rem - 3.25rem);
    padding-top: 1.5rem;

  }
  .page__container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 1350px;
    // min-height: 100vh;
    @media (min-width: 992px){
      height: calc( 40rem - 3.25rem);
    }
  }
}
.link_text{
  display: block;
  text-transform: uppercase;
  min-width: 10rem;
  margin-left: 1rem;
  font-family: 'Oswald';
}
.page__linkIcon{
  display: flex;
  align-items: center;
  svg{

    min-width: 1.75rem;
  }
}
.swiper-pagination-bullet{
  margin: 0 18px !important;
  padding: 20px 1px !important;
  transform: rotate(-25deg);
  border-radius: 0;
  background-color: #fff;
  opacity: 1;
  width: 1px;
  span{
    font-size: 30px;
    opacity: 0;
    left: -8px;
    position: relative;
  }
}
.swiper-pagination-bullet-active{
  background-color: $orange;
}
.swiper-pagination{
  position: absolute;
  left: 0;
  right: 0;
  @media screen and (min-width: 992px){
    top: 1rem;
  }
  @media screen and (max-height: 720px) and (min-width: 992px){
    top: 1rem;

  }
  @media screen and (max-width: 991px){
    display: none;
  }
  width: 100%;
  z-index: 6;
  line-height: 8pt;
  text-align: left;
  bottom: auto;
  width: 100%;
  max-height: 3rem;
  max-width: 1350px;
  margin: 0 auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.intro__slideImg {
    position: absolute;
    width: 100%;
    height: 100%;
}
.intro__slideImg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(img/slider/slide.jpg);
    background-size: cover;
    @media screen and (min-width: 992px){
      background-position: center;
    }
}
.intro__slideContent {
    position: relative;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    width: 80%;
    margin: auto;
}
.intro__sliderCategory {
    width: 100%;
    font-size: 4.6875rem;
    line-height: 1;
    font-weight: 300;
    font-family: 'Oswald';
    @media (max-width: 991px){
      font-size: 3rem;
    }
}
.intro__sliderTitle {
    width: 100%;
    font-size: 6.25rem;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 1rem;
    font-family: 'Oswald';
    line-height: 1;
    margin-top: 1rem;
    @media (max-width: 991px){
      font-size: 3rem;
    }
}
.intro__sliderText, .intro__sliderText p{
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;

    line-height: 1.5;
}
.intro__sliderText .text__paragraph {
    width: 40%;
    @media (max-width: 991px){
      width: 100%;
    }
}


.intro__iconControl{
  body.noTouchscreen &{

    a:hover{
      opacity: 1;
    }
  }
}
.Intro__scroll {
    position: absolute;
    bottom: 5rem;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'Oswald';
}
.intro__scrollBg {
    position: absolute;
    top: -2.8rem;
    opacity: 0.2;
    font-size: 3rem;
    text-transform: none;
    width: 10rem;
    margin-left: -5rem;
}
.intro__scrollFront {
    position: absolute;
    top: 0rem;
    font-size: 1.1rem;
    width: 10rem;
    margin-left: -5rem;
}
.Intro__scroll {
  @media (min-width: 992px){
    position: absolute;
    bottom: 4rem;
    width: 100%;
    text-align: center;
    z-index: 1;
  }
  @media (max-width: 991px){
    display: none;
  }
}
.text__paragraph{
  color: white;
}

@media screen and (max-width: 1400px){

}
@media screen and (max-width: 1146px){

}
@media screen and (max-width: 991px){
  .intro__sliderTitle {
    font-size: 3rem;
  }

}
@media (max-width: 991px){
  .intro__slide{
    height: auto;
  }
  .intro__slideContent{
    width: 100%;
  }
}
@media screen and (max-width: 767px){
  .intro__sliderText {
      margin-bottom: 0rem;
      display: none;
  }
}

@media screen and (max-width: 550px){

  .intro__sliderTitle{
    font-size: 2em;
  }
  .intro__sliderCategory{
    font-size: 2rem;
  }
}
@media screen and (max-width: 320px){

}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
.Intro__scroll {
    left: 50%;
}
}
.page__container--big{
  position: relative;
}
.main-slider__progress{
  position: absolute;
  z-index: 1;
  bottom: 4rem;
  right: 1rem;
  height: 3.5rem;
  width: 3.5rem;
  @media (max-width: 767px){
    bottom: 1rem;
    width: 2.75rem;
    height: 2.75rem;
  }
}
