&{
  background-color: $black;
}
#index &{
  padding: 2rem 0;
}
#index & .products{
  flex-wrap: nowrap;
}

&{
  position: relative;
  .page__container{
    position: relative;
  }
}
.bestseller__desc{
  margin-top: -0.5rem;
}
.buttons {
    display: inline-flex;
    position: absolute;
    right: 0;
    top: 0;
    .slider__buttons{
      display: inline-flex;
    }
    @media (max-width: 900px){
    }
    @media (max-width: 500px){
      .slider__buttons{
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
      }
      .btn-light{
        margin-right: auto;
      }
      display: flex;
    }
}
.buttons .btn-slider {
    opacity: 0.7;
    transition: 0.3s;
    cursor: pointer;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
}
.btn-slider svg {
    width: 1rem;
    height: 1.25rem;
    fill: $black ;
    transition: 0.3s;
    #pusty{
      opacity: 1;
      transition: 0.3s;
    }
    #pelny{
      opacity: 0;
      transition: 0.3s;
    }
}
  .buttons .btn-slider .opinions__arrow svg .fil0, .buttons .btn-slider .opinions__arrow svg{
    width: 1rem;
    height: 1.25rem;
    fill: $black;
    margin-left: 0.25rem;
  }
  body.noTouchscreen &{
  .buttons .btn-slider:hover {
      opacity: 1;
      transition: 0.3s;
  }
 .buttons .btn-slider:hover:not(.swiper-button-disabled) .opinions__arrow svg, .buttons .btn-slider:hover:not(.swiper-button-disabled) .opinions__arrow svg .fil0 {
    opacity: 1;
    fill: $orange;
    transition: 0.3s;

    #pusty{
      opacity: 0;
      transition: 0.3s;
    }
    #pelny{
      opacity: 1;
      transition: 0.3s;
    }
  }
}

.opinions__arrow svg {
  left: 0;
  top: 0.125rem;
  position: relative;

}
.opinions__arrow.arrow_Left svg {
	-webkit-transform: rotate( -180deg );
    -ms-transform: rotate( -180deg );
    transform: rotate( -180deg );
    left: -0.175rem;
    top: 0.125rem;
    position: relative;
}

.add-product{
  margin-right: 4rem;

}
@media (max-width: 991px){
  .add-product_wrapper{
    display: none;
  }
}
.btn-slider{
  opacity: 0.7;
  &.swiper-button-disabled{
    &:hover {
      opacity: 0.7 !important;
    }
    .opinions__arrow svg {
      fill: $black;
      transition: 0.3s;
      #pusty{
        opacity: 1;
        transition: 0.3s;
      }
      #pelny{
        opacity: 0;
        transition: 0.3s;
      }
    }
  }
}
.btn-slider.btn-icon {
    width: 2.9rem;
    height: 2.9rem;
    padding: 0.7rem 0.7rem;
    border-radius: 100%;
    position: relative;
}
.thumbnail-container{
  margin-left: auto;
  margin-right: auto;
}
.btn-slider.swiper-button-disabled{
  background: #ffffffaa;
  cursor: auto;
  pointer-events: none;

  svg {
    opacity: 0.5!important;
    #pusty{
      // opacity: 0.5!important;
    }
  }
}
@media (max-width: 767px){
  .buttons{
    display: none;
  }
  .bestseller__desc{
    display: none;
  }
}

.swiper-scrollbar {
  background-color: $slider_gray;
  // margin-top: 2rem;
}
.swiper-container{
	padding-bottom: 2rem;
}
