& {

  position: fixed;
  width: 100%;
  padding-right: 0rem;
  z-index: 99;
  //height: 3.75rem;

}
.nav__mainNav{
  display: flex;
  color:#fff;
  background: $black;
  -webkit-box-shadow: 0px 4px 7px -1px rgba($black,0.75);
-moz-box-shadow: 0px 4px 7px -1px rgba($black,0.75);
box-shadow: 0px 4px 7px -1px rgba($black,0.75);
}
.page__home &  {

}
.nav__basket .hidden-sm-down{
  display: none;
}
.nav__logo {
  display: flex;
  align-items: center;
}
.nav__logo svg {
  width: 12.5rem;
  height: auto;
  fill: #fff ;
  stroke: #fff;
  max-height: 3rem;
  @media screen and (min-width: 992px) and (max-width: 1050px){
    width: 10rem;

  }
}
.nav__logo a {
  margin-left: 2rem;
}
.nav__items {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  align-items: flex-start;
  font-weight: bold;
}
.nav__item {
  border: 1px solid #fff;

  border-bottom: 0px;
  border-top: 0px;
  border-color: rgba(255, 255, 255, 0.5);
  
  padding: 1rem 1rem;
  border-right: none;
  font-family: 'Oswald';
  font-size: 0.875rem;
  min-height: 3.75rem;
  .nav__category, .nav__user{
    cursor: pointer;
    position: relative;
    top: 0.125rem;
  }
  .nav__language{
    cursor: pointer;
    position: relative;
    top: 0.0625rem;
  }
  .nav__basket a{
    cursor: pointer;
  }
  .nav__basket{
    position: relative;
    top: 0.125rem;
  }

  &.orange{
    background-color: $orange;
  }
  display: flex;
  text-transform: uppercase;
  align-items: center;
  .nav__language .dropdown .expand-more{
    color: white;
    text-transform: uppercase;
  }

  div.nav__user, div.nav__basket, div.nav__language {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  div.nav__user{
    .user-info a{
    margin-left: 0.50rem;
    margin-right: 0.50rem;
    }
  }
  .search-widget form button[type=submit]{
    color: white !important;
    bottom: 0rem;
  }
  .search-widget form input[type=text], .search-widget form input[type=text]:focus, , .search-widget form input[type=text]:hover{

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: white !important;
    }
    &::-moz-placeholder {
      color: white !important;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: white !important;

    }
    &:-moz-placeholder {

      color: white !important;
      opacity: 1;
    }
    &::placeholder{
      opacity: 1;
      color: white !important;

    }
    color: white;
    position: relative;
    top: -0.125rem;
  }
  .material-icons{
    font-size: 1.125rem !important;
    position: relative;
    top: -0.125rem;
  }
}
#search_widget{
  @media screen and (min-width: 992px){
    min-width: 18rem;
    input[type=text]{
      min-width: 16rem;
    }
    .material-icons{
      top: 0;
      font-size: 1.25rem !important;
    }
  }
  @media screen and (min-width: 992px){
    min-width: 14rem;
    input[type=text]{
      min-width: 10rem;
    }
  }
  @media screen and (max-width: 500px){
    width: 100vw;
    input[type=text]{
      width: calc(100vw - 6rem);
      padding-bottom: 0.25rem;
    }
  }
}
.nav__item.orange{
  border-right: 1px solid;
}
.nav__searchIcon {
  display: inline-block;
}

& input, & select {
  font-size: inherit;
  font-weight: bold;
  color: #fff;
  background: transparent;
  border: none;
  padding: 0;
  // height: 1.1rem;
}
& select {
  text-transform: uppercase;
}
& ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
    opacity: 1; /* Firefox */
  }

& :-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #fff;
}

& ::-ms-input-placeholder { /* Microsoft Edge */
  color: #fff;
}
.main_input.nav_input {
  width: 19rem;
}
.nav__pageLinks a {
  text-transform: uppercase;
  margin-right: 1.1rem;
}
.nav__pageLinks a:last-child{
  margin-right: 0rem;
}
@media screen and (min-width: 992px){
  .hamburger-mobile, .search-mobile{
    display: none;
  }
}
@media screen and (max-width: 991px){
  & {
    border-bottom: 1px solid white;
  .nav__mainNav{

    display: flex;
    justify-content: space-between;
  }
  }
  .nav__item{
    display: none;
  }

  .nav__mobile{
    display:flex;
    align-items: center;
    margin-right: 2rem;
    .search-mobile .material-icons{
      width: 2rem;
      height: 2rem;
      font-size: 2rem !important;
    }
  }
  .hamburger-mobile{
    $hamburger-layer-width                     : 1.75rem;
    $hamburger-layer-height                    : 3px;
    $hamburger-layer-spacing : 6px;
    @import '../hamburgers/hamburgers.scss';
  }
  .search-mobile{
    cursor: pointer;
    margin-right: 1rem;
  }
  .nav__item .nav__search{
    position: absolute;
    right: 0;
    transform: translateY(-30rem);
    transition: all 0.3s ease;
    background-color: $black;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 1rem;
    z-index: 9;
    font-size: 1rem;
    color: white;
    border-bottom: 1px solid white;
    display: flex;
    width: 20rem;
    @media screen and (max-width: 500px){
      right: 0rem;
      min-width: 100%;
      input[type=text]{
        min-width: 100%;
      }
    }
    button[type=submit]{
      position: absolute;
      right: 0.5rem;
      top: 0.375rem;
    }


    &.active{
      transform: translateY(3.75rem);
      @media screen and (max-width: 500px){
        transform: translateY(3.75rem);
      }
      .search-widget form input[type=text]{
        & .safari-browser{
          min-height: 2rem;
        }
      }
      @media screen and (max-width: 500px){
        right: 0;
        left: 0;
        .search-widget, .search-widget form input[type=text]{
          width: 100%;
          color: white !important;
          }
          .search-widget form input[type=text]{

            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
              color: white !important;
              opacity: 1;

            }
            &::-moz-placeholder {
              color: white !important;
              opacity: 1;
            }
            &:-ms-input-placeholder {
              color: white !important;
              opacity: 1;

            }
            &:-moz-placeholder {

              color: white !important;
              opacity: 1;
            }
            &::placeholder{
              opacity: 1;
              color: white !important;

            }
            color: white !important;
          }


      }
    }
  }
  .mob__search{
    display: block;
    border: 0px solid white;
  }


}
@media screen and (max-width: 767px){

}
@media screen and (max-width: 500px){
  .nav__logo svg{
   width: 10rem;
 }
 .nav__logo a {
  margin-left: 0.5rem;
}
.nav__mobile{
  margin-right: 0.5rem;
}

.nav__item{
 border: none;
 padding: 0.5rem 0.5rem;
}

.nav__pageLinks a {
  text-transform: uppercase;
  margin-right: 0rem;
  margin-left: 1.1rem;
  display: block
}
.nav__item:last-child {
  border-right: none;
  margin-top: none;
  margin-right: none;
}
}
@media screen and (max-width: 320px){
  .nav__logo svg{
   width: 7rem;
 }
}
.megamenu__wrapper{
  // transform: translateY(-30rem);

  // opacity: 0;
  // pointer-events: auto;
  box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.75);


  // display: none;
  overflow:hidden;
  height: 0rem;

  position: absolute;
  // transition: all 0.3s ease;
  background-color: #2c2c2f;

  cursor: auto;
  .megamenu__categories{
  }
  .megamenu__category{
    min-width: 13rem;
  }

  left: 0rem;
  top: 3.5rem;
  .megamenu__category a{
    cursor: pointer;
  }
}

.nav__item.nav__menu-wrapper{
    padding: 0rem;
  .nav__button{
        padding: 1rem 1rem;
  }

}


.nav__basket--wrapper{
  #_desktop_cart{

    .-hide_mob{
      display: none;
    }
  }
}

// pojedynczy

.megamenu__category a{
  padding: 0.5rem 1rem;
  display: inline-block;
  width: 100%;
}

.megamenu__category a:hover, .megamenu__category.current a{
  background-color: $orange;
  opacity: 1;
}

.nav__search{
  position: relative;
}
#clear__btn{
  display: none;
  @media (max-width: 991px){
    cursor: pointer;
    position: absolute;
    font-size: 3.5rem;
    color: white;
    font-weight: 200;
    top: -1.625rem;
    @media screen and (max-width: 500px){
      top: -1.5625rem;
    }
    right: 2.5rem;
    display: block;
  }
}
@media (max-width: 991px){
  .nav__item .material-icons {
    position: relative;
    font-size: 1.5rem !important;
    &.search{
      top: -0.0625rem;
      @media screen and (max-width: 500px){
        top: 0.0625rem;

      }
    }
  }
  .nav__basket--wrapper{
    display: block;
    .nav__user{
      display: none;
    }
    #_desktop_cart{
      position: relative;
      top: -0.175rem;
    }

    .cart-products-count{
      position: absolute;
      bottom: -12px;
      right: -12px;
      background-color: $orange;
      min-width: 1.325rem;
      min-height: 1.325rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 0.75rem;

    }
    position: relative;

    border: 0px solid;
    border-right: 0px solid !important;
    background-color: transparent !important;
  }
  .nav__items{
    order: -1;
    width: auto;
  }
  .mob__search{
    max-width: 0;
    min-height: 0;
    height: 0;
    width: 0;
    padding: 0;

  }

}
// // fix mmenu  scroll top
@media (max-width: 991px){


  .mm-wrapper_opening &{
    //	Animations

    transform: translateX( -250px );
  }
  &{
    $mm_transitionDuration 			: 0.4s;
    $mm_transitionFunction 			: ease;
    transition: all $mm_transitionDuration $mm_transitionFunction;

  }
}
