.shop-filter__component_new{
  height: 3.75rem;
  display: flex;
  align-items: center;
}
.shop-filter__options-list_new{
  display: flex;
  justify-content: flex-start;
  padding: 1rem;
  flex-wrap: wrap;
}
.shop-filter__option_new{
  padding: 1rem;
  button{
    cursor: pointer;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: .3s ease;
  position: relative;
  margin-right: calc(0.75rem - 4px);
  border: 2px solid transparent;
  transition: all 0.375s ease;
  img{
    display: block;
    max-width: 8rem;
    max-height: 8rem;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    mix-blend-mode: multiply;
  }
  &.is-selected{
    background-color: white;
    button{

    }
    border: 2px  solid $orange;

  }
  &:hover{
    opacity: 1;
  }
  & button:hover{
    opacity: 1;
  }
}

@media (min-width: 700px){
    .stage__next.btn{
      position: absolute;
      top: auto;
      bottom: -1.5rem;
      z-index: 2;
      right: 2rem;
    }
}
@media (max-width: 699px){
    .stage__next.btn{
      margin-bottom: 1rem;
    }
}
// .stage_step_2_edit .stage__next.btn{
//     bottom: 3.5rem;
//   }

.current-price{
  color: white;
  font-family: $font_default;
  font-size: 1.125rem;
}
.product__price{
  font-family: 'Oswald';
  font-size: 2.25rem;
  color: $orange;

}
.currency{
  font-family: 'Oswald';
  font-size: 2.25rem;
  color: $orange;
  font-size: 1.125rem;
  position: relative;
  top: -1.125rem;
}

.special__descriptnio{
  font-size: 1rem;
  line-height: 1.375;
  font-weight: 300;
  margin-top: 1rem;
}
.shop-filter__component__subtitle{
  width: 22rem;
  text-align: left;
}
.shop-filter__component__icon{
  min-width: 3.75rem;
}
.shop-filter__options-list{
  padding: 0;
}
.shop-filter__component__title{
  text-align: left;
  text-transform: uppercase;
}
.btn.new{
  background-color: $gray_presta;
  position: absolute;
  left: auto;
  right: 2rem;
  top: 0.875rem !important;
  bottom: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 2rem !important;
  height: 2rem;
  font-size: .75rem !important;
}
.shop-filter__component{
  position: relative;
}
.shop-filter__component:after{
  display: none;
}
.bc_option-text{
  text-align: center;
  margin-top: 0.5rem;
  // width: 100%;
  display: block;
}
.input-group{
  max-width: 4rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.5rem;
}
.app_preloader{

  width: 100%;
  i{
    color: $orange;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 3rem;
    max-width: 3rem;
  }
}
.columns{
  display: flex;
  width: 100%;
}
.column{
  width: 50%;
  flex-basis: 50%;
}
.btn {
  cursor: pointer;
}
@media screen and (max-width: 991px){
  .btn.new{
    display: none;
  }
  .columns{
    flex-direction: column;
  }
  .column{
    width: 100%;
    flex-basis: 100%;
  }
  .product__stages {
    order: -1;
    position: relative;
  }
}
@media screen and (max-width: 600px){
.shop-filter__component__subtitle{
    padding-right: 0;
  }
  .shop-filter__option_new{
    margin-right: auto;
    margin-left: auto;
  }
  .shop-filter__component__icon{
    min-width: 3.75rem;
  }

  .shop-filter__component__title{
    max-width: 7rem;
    min-width: 7rem;
  }
  .shop-filter__component__subtitle{
    max-width: 33vw;
  }
}
.product__preview{
  height: auto;
  position: relative;
  @media screen and (min-width: 992px){
    margin-right: 1.25rem;
  }
  @media screen and (max-width: 991px){
    margin-top: 2.25rem;
  }
  img{
    position: absolute;
    top: 0;
    left: 0px;
  }
  .product__preview-base img{
      position: relative;
  }
}
.bc_option-text{
  text-transform: uppercase;
}
.stage_step_2_edit .shop-filter__option_new{
  max-width: 6rem;
  img{
    max-height: 3.8125rem;
    max-width: 3.8125rem;
  }
  .bc_option-text{
    // font-size: .625rem;
    // font-weight: 900;

    font-weight: 400;
    font-size: 0.9rem;
    line-height: 1.4;
    
    text-transform: none;
  }
}
.bc_price-text{
  font-family: 'Oswald';
  font-size: 1.25rem;
  color: $orange;
  &::after{
    content: 'PLN';
    font-size: 0.675rem;
    position: relative;
    top: -0.375rem;
    left: 0.25rem;
  }
}
.shop-filter__option_new .not-active-input{
  min-height: calc(3rem + 2px);
  @media screen and (max-width: 991px){
    min-height: calc(2.75rem + 2px);
  }
}
.shop-filter__option_new.is-selected .not-active-input{
  display: none;
}
.stage__options:not(.stage_step_2_edit){
  .shop-filter__option_new {
    width: 10rem;
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
    .bc_option-text{
      font-family: 'Oswald';
      width: 100%;
    }
    @media screen and (max-width: 991px){
      padding: 0.125rem;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;

      .shop-filter__option_new img{
        height: auto;
      }
      width: 9rem;
    }
  }
}
.stage__step-3{
  img.product{
    min-height: 7rem;
    max-width: 7rem;
  }
}
#ag_bottom_add_to_cart{
  i.fa{
    display: none;
  }
  i.material-icons{
    display: block;
    margin-right: 0.25rem;
  }
  span{
    display: flex;
  }
}
#ag_bottom_add_to_cart.pointer-none{
  pointer-events: none;
  i.fa{
    display: block;
  }
  i.material-icons{
    display: none;
  }
  span{
    display: none;
  }
}
.input-inline{
  display: block;
    color: $orange;
    font-size: 1rem;
    text-align: center;
    padding: 0.5rem;
    cursor: pointer;
}
.input-wrapper{
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.input-dummy{
  height: 4rem;
  width: 1px;
}
.product__buy{
    min-width: 9rem;
    max-height: 2.75rem;
    min-height: 2.75rem;
    i{
      font-size: 1rem;
    }
}
.stage__change--step2{
 .selected_img{
		max-height: 2.5rem;
		max-width: 2.5rem;
		height: 2.5rem;
		width: 2.5rem;
		margin-left: 1rem;
		@media screen and (max-width: 767px){
			display: none;
		}
	}
	.shop-filter__component__subtitle{
		display: flex;
		align-items: center;
	}
}
@media screen and (min-width: 992px){
  .product__preview{
    position: sticky;
    top: 7rem;
    // height: 20rem;
    // margin-bottom: 4rem;
    height: 35rem;
  }
.stage__edit--step1.stage__options .shop-filter__option_new{
 width: 32%;
 img {
    display: block;
    max-width: 100%;
    max-height: 10rem;
	}
  @media screen and (max-width: 1200px){
    width: 15.75rem;
   img {
      display: block;
      max-width: 100%;
      max-height: 10rem;
    }
  }
}
}
.product__stages{
  position: relative;
}


//@import '1_ag.scss'; // style pluginu attribute grid
