.card-title.h3{
  font-size: 1.5rem;
}
#order-details{
  padding-left: 15px;
}
#order-items{
  @media screen and (min-width: 768px){
    width: 66.666666%;
  }
}
