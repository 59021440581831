// ################ VARIABLES ONLY ###########
$font_default: 'Roboto', sans-serif;
$awesome: 'FontAwesome';

// $container-width: 72rem;
$container-width: 84.375rem;
$container-big-width: 107.625rem;

$container-padding: 1rem;

$black: #141417;
$orange: #ef6d1f;
$gray: #f2f2f4;
$gray_presta: #7A7A7A;
$color_default:  $black;
$slider_gray: #2c2c2f;
// selekcja
$selection_bgn: $black;
$selection_color: white;
$gray_lighter_price-steel: #d7d7dd;
$gray-secondary: $gray_lighter_price-steel;
// breakpoint
$white: #f4f4f4;
$white_collapse: white;
$desktop-breakpoint:  $container-width + $container-padding + $container-padding;
