& {

  background-image: url(img/article/article1.jpg);
  background-size: cover;
  transition: 0.15s;
  transition-delay: 0.1s;
  z-index: 0;
  overflow: hidden;

}
& {
    padding: 5rem 0rem;
    border-top: 0.5rem solid $black ;
    position: relative;
    @media screen and (max-width: 767px){
      &::before{
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background:url( data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAIUlEQVQYV2MUERExZkACjCIi4r4MDH+fMTAwS4FoRoIqAPtkCcEWnfMTAAAAAElFTkSuQmCC) repeat;
      }
    }
}
.articles__items {
    z-index: 2;
    position: relative;
    align-items: normal;
    justify-content: center;
    display: flex;
    grid-gap: 0;
    flex-wrap: wrap;
}
.articles__item {
    padding: 2rem 1rem;
    flex-basis: 25%;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      flex-basis: calc(25% - 2rem);

    }
}
.articles__item--wraper {

  z-index: 1;
}

.articles__title h2 {
    text-transform: none;
}
.articles__textWrap {
  display: flex;
  flex-wrap: wrap;
  min-height: 12rem;
  max-width: 20rem;
}
.articles__title {
    width: 100%;
    line-height: 1.2;
}
.articles__short {
    width: 100%;
    color: white;

}
.articles__short p{
  font-size: 1.4rem;
  margin-top: 0.75rem;
  color: white;
  @media (min-width: 992px){
    // margin-right: 5rem;
  }
}
.articles__link span{
  display: block;
text-transform: uppercase;
min-width: 10rem;
margin-left: 1rem;
font-family: 'Oswald';
}
.page__linkIcon{
  display: flex;
  align-items: center;
      font-style: normal;
  svg{

    min-width: 1.75rem;
  }
}

body.noTouchscreen &{

  .articles__item:hover {
    // color: $orange;

      transition: 0.15s;
      transition-delay: 0.15s;
      .articles__textWrap{
        .articles__title h2, .articles__short p{
          text-shadow: 1px 1px 4px $black , 1px 1px 4px $black ;
          color: $orange;
        }
      }
  }

  .articles__item:hover .page__linkIcon:after{
      transform: rotate(0deg);
      transition: 0.3s;
      transition-delay: 0.15s;

  }
  .articles__item:hover{  }
  a.articles__item:hover{
    opacity: 1;

  }
}
.articles__title h2, .articles__short p{
transition: all 0.15s ease;
transition-delay: 0.15s;
}

@media screen and (max-width: 1400px){

}
@media screen and (max-width: 991px){
  .articles__item{
    flex-basis: 50%;
  }
& {
    padding: 1rem 0rem;
}

}
@media screen and (max-width: 767px){

& {
    padding: 2rem 0rem;
    background-image: url(img/article/article_mob.jpg) !important;
    background-position: center 0%;
}
.articles__title h2{
        font-size: 2rem;
}
.articles__short p {
    font-size: 1.1rem;
}
.articles__textWrap {
    min-height: 5rem;
}
.articles__item
{
  flex-basis: 100%;

  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  border: 1px solid rgba(white, 0.2);
}
.articles__short{
  margin-bottom: 0.25rem;
}

}
@media screen and (max-width: 767px){
  .articles__items{
    flex-basis: 100%;
  }
  .articles__textWrap{
    max-width: 100%;
  }
  .articles__items{
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
.articles__items{
    // grid-template-columns: 1fr;
    // .articles__item{
    //   padding-left: 0.5rem;
    //   padding-right: 0.25rem;
    //   padding-top: 0.25rem;
    //   padding-bottom: 0.25rem;
    //   margin-top: 0.25rem;
    //   margin-bottom: 0.25rem;
    //   margin-left: 0.25rem;
    //   margin-right: 0.25rem;
    //   .articles__short p {
    //       font-size: 0.675rem;
    //   }
    //   .articles__title h2{
    //       font-size: 1.25rem;
    //   }
    //   .articles__link span{
    //     font-size: 0.5rem;
    //     margin-left: 0.25rem;
    //
    //   }
    // }

}

}
@media screen and (max-width: 320px){

}
