#cookieinfo{

	// ulozenie wzgledem strony

	// position: absolute;
	// position: fixed;

	z-index: 11;


	// wyswietlaj i nie wyswietlaj

	display: none;

	&.visible{
		display: block;
	}

	// wymiary i polozenie


		width: 100%;
		// left: 0px;
		// bottom: 0px;
		padding: 1.6rem;
		padding-right: 2.5rem; // zeby guzik zamknij mial miejsce


	// kolory

	background-color: $orange;
	color: white;
	box-shadow: 1px 1px 5px 0px rgba($black, 0.75);

	// typografia

	.description{
		font-size: 0.8rem;
		line-height: 1.2rem;
		font-weight: 300;
	}

	@media (max-width: 600px) {
		.description{
			font-size: 10px;
			line-height: 14px;
		}
	}

	// guzik zamknij

	.close{
		position: absolute;
		top: 0.15rem;
		right: 0.6rem;
		font-size: 1.5rem;
		color: white;
		opacity: 0.7;
		font-weight: 200;
		@media (max-width: 991px) {

			// caly kukis sluzy do zamykania
			width: 100%;
			height: 100%;

			span{
				position: absolute;
				top: 0px;
				right: 0px;
			}
		}
	}
}
