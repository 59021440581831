.page__subPageTitle h2{
  font-family: 'Oswald';
  text-transform: uppercase;
}
.text__heading{
    font-family: 'Oswald';
    font-weight: 400;
    font-size: 2.25rem;
    @media screen and (max-width: 500px){
      font-size: 1.75rem;
    }
    letter-spacing: -0.0625rem;
    text-transform: uppercase;
    @media screen and (min-width: 992px){
      // width: 48.375rem;
      // max-width: 45%;
    }
    z-index: 1;
    position: relative;
}
.about__middle--wrapper{
  display: flex;
  @media screen and (max-width: 991px){
    display: block;
    .about__wrapper--right{
      margin-top: 1.5rem;

    }
  }
  .about__wrapper--left{
    @media screen and (min-width: 992px){
      width: 21.875rem;
      margin-right: 3.5rem;
    }
    flex-shrink: 0;
    .text__heading{
      margin-top: 2rem;
    }
  }
  .about__wrapper--right{
    @media screen and (min-width: 992px){
      .text__heading, p{

        margin-left: 4.5rem;
      }
      .text__heading {
        margin-bottom: 1.5rem;
      }
    }
    img{
      @media screen and (min-width: 992px){
        margin-top: 4.5rem;
      }
    }
    .text__heading{
      @media screen and (max-width: 991px){
        margin-bottom: 2rem;
      }
    }

  }
  @media screen and (min-width: 992px){
    span.break{
      display: block;
    }
  }
  @media screen and (max-width: 991px){

    span.break{
      display: inline-block;
    }
  }
}
