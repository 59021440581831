// TEKST

.page__text-normal{

	ul
	{
		list-style: disc;
	}

	ul, ol{
		padding-left:1.4rem;
		padding-bottom:1.5rem;
		line-height: 1.5rem;
	}
	p{
		font-size: 1rem;
		padding-bottom:1.5rem;
		line-height: 1.5rem;
	}

	// a{
	// 	color:blue;
	// }

	// a:hover{
	// 	color:rgba(blue, 0.7);
	// }


}
.text__negMargin--top{
	margin-top: -1.75rem;
}
.text__negMargin--bottom{
	margin-bottom: -1.5rem;
}
.page__section_dark{
  .h1, .h2, .h3{
    color: white;
  }
}
.h1, .h2, .h3, h1, h2, h3, h4{
  font-size: 3rem;
  font-family: 'Oswald';
	text-align: left;
	&[itemprop=name]{
		font-size: 2rem;
	}
}

.btn:not([disabled]):not(.disabled):not(.btn-touchspin):not(.btn-slider):not(.black):not(.white){
	opacity: 1;
	position: relative;
	overflow: hidden;
	-webkit-backface-visibility: hidden;
	 -moz-backface-visibility: hidden;
	 -webkit-transform: translate3d(0, 0, 0);
	 -moz-transform: translate3d(0, 0, 0);
	 &:not([data-action=show-password]){
		 min-height: 2.875rem;
		 @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			 height: 2.875rem;
		 }
		 @supports (-ms-ime-align:auto) {
			 height: 2.875rem;
		 }
	 }

	background-color: $orange;
	&.btn-secondary{
		background-color: $white;
		color: $black;
	}
	&:focus, &:hover{
		background-color: $orange;
	}
	&.btn-light{
		background-color: white;
		color: $black;
	}
	&.btn-dark{
		background-color: $black;
		color: $orange;
	}
	&.btn-light:focus, &.btn-light:hover{
		background-color: white;
		color: $black;
	}
	&.btn-secondary:focus, &.btn-secondary:hover{
		background-color: $white;
		color: $black;
	}
	&:after {
		content: '';
		height: 20rem;
		position: absolute;
		transform: translate3d(-10%, -10px, 0) rotate3d(0, 0, 1, 20deg);
		transition: all 3.25s cubic-bezier(0.19, 1, 0.22, 1);
		width: 8rem;
		top:-7rem;
		left: -9rem;
		// z-index: -1;
	}
	&:hover:after{
		transform: translate3d(550%, -20px, 0px) rotate3d(0, 0, 1, 45deg);
	}
	&:focus:after{
		transform: translate3d(550%, -20px, 0px) rotate3d(0, 0, 1, 45deg);
	}
	&:after {
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1e5799+0,141417+10,141417+90,7db9e8+100&0+0,1+10,1+90,0+100 */
		background: -moz-linear-gradient(left, rgba($black,0) 0%, rgba($black,0.5) 10%, rgba($black,0.5) 90%, rgba($black,0) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left, rgba($black,0) 0%,rgba($black,0.5) 10%,rgba($black,0.5) 90%,rgba($black,0) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right, rgba($black,0) 0%,rgba($black,0.5) 10%,rgba($black,0.5) 90%,rgba($black,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001e5799', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
	}

}
.btn.black{
	opacity: 1;
	position: relative;
	overflow: hidden;
	-webkit-backface-visibility: hidden;
	 -moz-backface-visibility: hidden;
	 -webkit-transform: translate3d(0, 0, 0);
	 -moz-transform: translate3d(0, 0, 0);
&:after{
	background-color: rgba(white, 0.5);
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1e5799+0,141417+10,141417+90,7db9e8+100&0+0,1+10,1+90,0+100 */
	background: -moz-linear-gradient(left, rgba(white,0) 0%, rgba(white,0.5) 10%, rgba(white,0.5) 90%, rgba(white,0) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left, rgba(white,0) 0%,rgba(white,0.5) 10%,rgba(white,0.5) 90%,rgba(white,0) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, rgba(white,0) 0%,rgba(white,0.5) 10%,rgba(white,0.5) 90%,rgba(white,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001e5799', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */

}

	&:after {
		content: '';
		height: 20rem;
		position: absolute;
		transform: translate3d(-10%, -10px, 0) rotate3d(0, 0, 1, 20deg);
		transition: all 3.25s cubic-bezier(0.19, 1, 0.22, 1);
		width: 8rem;
		top:-7rem;
		left: -9rem;
		// z-index: -1;
	}
	&:hover:after{
		transform: translate3d(550%, -20px, 0px) rotate3d(0, 0, 1, 45deg);
	}
	&:focus:after{
		transform: translate3d(550%, -20px, 0px) rotate3d(0, 0, 1, 45deg);
	}
	&:hover {
		opacity: 1;
	}
}
.btn.white_orage{
	background-color: white;
	color: rgba(239,109,31,1);
	opacity: 1;
	position: relative;
	overflow: hidden;
	-webkit-backface-visibility: hidden;
	 -moz-backface-visibility: hidden;
	 -webkit-transform: translate3d(0, 0, 0);
	 -moz-transform: translate3d(0, 0, 0);
	 &:after{
		 /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1e5799+0,141417+10,141417+90,7db9e8+100&0+0,0.33+10,0.33+90,0+100 */
		 background: -moz-linear-gradient(left, rgba(30,87,153,0) 0%, rgba(20,20,23,0.33) 10%, rgba(20,20,23,0.33) 90%, rgba(125,185,232,0) 100%); /* FF3.6-15 */
		 background: -webkit-linear-gradient(left, rgba(30,87,153,0) 0%,rgba(20,20,23,0.33) 10%,rgba(20,20,23,0.33) 90%,rgba(125,185,232,0) 100%); /* Chrome10-25,Safari5.1-6 */
		 background: linear-gradient(to right, rgba(30,87,153,0) 0%,rgba(20,20,23,0.33) 10%,rgba(20,20,23,0.33) 90%,rgba(125,185,232,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		 filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001e5799', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
	 }

		 &:after {
			 content: '';
			 height: 20rem;
			 position: absolute;
			 transform: translate3d(-10%, -10px, 0) rotate3d(0, 0, 1, 20deg);
			 transition: all 3.25s cubic-bezier(0.19, 1, 0.22, 1);
			 width: 8rem;
			 top:-7rem;
			 left: -9rem;
			 // z-index: -1;
		 }
		 &:hover:after{
			 transform: translate3d(550%, -20px, 0px) rotate3d(0, 0, 1, 45deg);
		 }
		 &:focus:after{
			 transform: translate3d(550%, -20px, 0px) rotate3d(0, 0, 1, 45deg);
		 }
		 &:hover {
			 opacity: 1;
		 }
}
.btn[disabled], .btn.disabled{
	background-color: $gray_presta;
	opacity: 1;
	&:hover, &:focus{
		background-color: $gray_presta;
		opacity: 1;
	}
	&:not([data-action=show-password]){
		min-height: 2.875rem;
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			height: 2.875rem;
		}
		@supports (-ms-ime-align:auto) {
			height: 2.875rem;
		}
	}
}
#checkout #checkout-personal-information-step .nav-item span{
	display: none;
}
.nav-item a.nav-link {
	min-height: 2.875rem;
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		height: 2.875rem;
	}
	@supports (-ms-ime-align:auto) {
		height: 2.875rem;
	}
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    background: $gray_presta;
    display: inline-block;
    border-radius: 3rem;
		font-size: 0.875rem;
		padding: 0.75rem 1.5rem;
    cursor: pointer;
		font-family: 'Oswald';
		color:white;
		&:hover, &:focus{
			opacity: 1;
			color:white !important;

		}
		opacity: 1;
		position: relative;
		overflow: hidden;
		-webkit-backface-visibility: hidden;
		 -moz-backface-visibility: hidden;
		 -webkit-transform: translate3d(0, 0, 0);
		 -moz-transform: translate3d(0, 0, 0);
		 &:after{
			 /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1e5799+0,141417+10,141417+90,7db9e8+100&0+0,0.33+10,0.33+90,0+100 */
			 background: -moz-linear-gradient(left, rgba(30,87,153,0) 0%, rgba(20,20,23,0.33) 10%, rgba(20,20,23,0.33) 90%, rgba(125,185,232,0) 100%); /* FF3.6-15 */
			 background: -webkit-linear-gradient(left, rgba(30,87,153,0) 0%,rgba(20,20,23,0.33) 10%,rgba(20,20,23,0.33) 90%,rgba(125,185,232,0) 100%); /* Chrome10-25,Safari5.1-6 */
			 background: linear-gradient(to right, rgba(30,87,153,0) 0%,rgba(20,20,23,0.33) 10%,rgba(20,20,23,0.33) 90%,rgba(125,185,232,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			 filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001e5799', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
		 }

			 &:after {
				 content: '';
				 height: 20rem;
				 position: absolute;
				 transform: translate3d(-10%, -10px, 0) rotate3d(0, 0, 1, 20deg);
				 transition: all 3.25s cubic-bezier(0.19, 1, 0.22, 1);
				 width: 8rem;
				 top:-7rem;
				 left: -9rem;
				 // z-index: -1;
			 }
			 &:hover:after{
				 transform: translate3d(550%, -20px, 0px) rotate3d(0, 0, 1, 45deg);
			 }
			 &:focus:after{
				 transform: translate3d(550%, -20px, 0px) rotate3d(0, 0, 1, 45deg);
			 }
			 &:hover {
				 opacity: 1;
			 }
}
.nav-item a.nav-link.active {
	min-height: 2.875rem;
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		height: 2.875rem;
	}
	@supports (-ms-ime-align:auto) {
		height: 2.875rem;
	}
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    background: $orange;
    display: inline-block;
    border-radius: 3rem;
		font-size: 0.875rem;

    cursor: pointer;
		font-family: 'Oswald';
		color:white;
		&:hover, &:focus{
			color: white !important;
			opacity: 1;
		}
		opacity: 1;
		position: relative;
		overflow: hidden;
		-webkit-backface-visibility: hidden;
		 -moz-backface-visibility: hidden;
		 -webkit-transform: translate3d(0, 0, 0);
		 -moz-transform: translate3d(0, 0, 0);
	&:after{
		background-color: rgba(white, 0.5);
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1e5799+0,141417+10,141417+90,7db9e8+100&0+0,1+10,1+90,0+100 */
		background: -moz-linear-gradient(left, rgba(white,0) 0%, rgba(white,0.5) 10%, rgba(white,0.5) 90%, rgba(white,0) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left, rgba(white,0) 0%,rgba(white,0.5) 10%,rgba(white,0.5) 90%,rgba(white,0) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right, rgba(white,0) 0%,rgba(white,0.5) 10%,rgba(white,0.5) 90%,rgba(white,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001e5799', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */

	}

		&:after {
			content: '';
			height: 20rem;
			position: absolute;
			transform: translate3d(-10%, -10px, 0) rotate3d(0, 0, 1, 20deg);
			transition: all 3.25s cubic-bezier(0.19, 1, 0.22, 1);
			width: 8rem;
			top:-7rem;
			left: -9rem;
			// z-index: -1;
		}
		&:hover:after{
			transform: translate3d(550%, -20px, 0px) rotate3d(0, 0, 1, 45deg);
		}
		&:focus:after{
			transform: translate3d(550%, -20px, 0px) rotate3d(0, 0, 1, 45deg);
		}
		&:hover {
			opacity: 1;
		}
}
.price, .current-price span[itemprop="price"]{
	font-family: 'Oswald';
}
.product-discount{
	font-family: 'Roboto';
	font-weight: 900;
	.regular-price{
		font-weight: 900;

	}
	font-size: 1.125rem;
	color: $gray_lighter_price-steel;
}
.tax-shipping-delivery-label{
	display: none;
}
#product-modal{
	figure{
		z-index:999;
	}
	.product-images img{
		min-width: 9.25rem;
	}
}
#footer{
	padding-top: 0;
	#section_newsletter{
		// padding-bottom: 1rem;
	}
}
@media (max-width: 992px){
.add-product{
		position: relative !important;
		margin-bottom: 1rem;

	}
	.add-product_wrapper{
		display: flex;
		justify-content: center;
	}
}
.swiper-scrollbar {
  background-color: $slider_gray;
	top: 95%;
	cursor: pointer;
	.swiper-container-horizontal > &{
		height: 3px !important;
		border: 1px solid transparent !important;
		cursor: pointer;
	}
}
.swiper-scrollbar .swiper-scrollbar-drag {
  height: 100%;
  background-color: $orange;
  height: 0.625rem;
	@media screen and (min-width: 992px) and (max-width: 1399px){
		top: -0.25rem;
	}
	@media screen and (max-width: 991px){
		top: -0.25rem;
	}
	@media screen and (min-width: 1400px){
		top: -0.25rem;
	}
  left: 0.125rem;
}
.swiper-container-free-mode .swiper-scrollbar{
	display: block;
}
.swiper-container:not(.swiper-container-free-mode) .swiper-scrollbar{
	display: none;
}
#index .page__container{
	@media (max-width: 991px){
		max-width: 600px;
	}
}
.bootstrap-touchspin input.form-control, .bootstrap-touchspin input.input-group{
	@media (min-width: 992px) and (max-width: 1400px){
		height: 2.625rem;
	}
}

#products .mob__search #clear__btn{
	display: none;
}
.title_acc{
	text-align: center;
	color: white;
}
.input-color:checked + span,  .input-radio:checked + span {
    border-color: $orange;
    color: $orange;
}
.radio-label{
  box-shadow: none;
}
.input-color + span, .input-color + span, .input-radio + span, .input-radio + span{
  border-color: $black;
  color: $black;

  transition: all 0.5s ease;
}
.control-label{
  font-size: 1.125rem;
  font-weight: bold;
}
.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up, .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down{
	border-bottom-right-radius: 0 !important;
	border-top-right-radius: 0 !important;
}
input[name=qty]{
	@media screen and (min-width: 992px) and (max-width: 1400px){
		height: 2.675rem;
	}
}
.bestseller-products .buttons.accessories_buttons{
	top: 3rem;
}
html.popup_visible_ask_popup body{
	overflow: hidden;
}
.ui-autocomplete{
	display: none !important;
}
body.overflow:not(.-is-tablet-mobile){
	overflow-x: hidden;
	overflow-y: scroll;
    // max-width: calc( 100vw - 50px );

	// main{
	// 	margin-right: 17px;
	// }
	// header .nav__mainNav{
	// 	padding-right: 17px;
	// }
}
body:not(.-is-tablet-mobile){
	// overflow-x: hidden;

}

body, header{
	max-width:1920px;
	margin: 0 auto;
}
body.overflow.-is-tablet-mobile{
	overflow: auto;
}
body.-cookieopen main{
	padding-top: 2rem;
}
body.-cookieopen#index main{
	padding-top: 4rem;
}
body#index main{
	padding-top: 2rem;
}
#authentication .custom-radio{
  position: relative;
  top: -0.125rem;
}
#product .container{
  padding-bottom: 1.25rem;
}
.page__cms{
	@media screen and (min-width: 992px){
		padding-top: 3rem;
	}
}
.product-id-23, .product-id-101{ //produkt specjalny szerszy kontener
	.container{
		@media (min-width: 1260px){
			width: 1200px
		}
		@media (min-width: 1440px){
			width: 1400px;
		}
		@media (min-width: 1680px){
			width: 1600px;
		}
	}
}
