&.page__container--top {
  background-size: cover;
}
.mob{
  display: none;
  @media screen and (max-width: 991px){
    display: inline-block;
    font-size: 2.75rem;
    margin-right: 1rem;
  }
}
.page__subPageText {
  @media screen and (min-width: 992px){

    width: 34.375rem;
    max-width: 45%;
  }
    position: relative;
    z-index: 1;


}
.text__heading{
  font-family: 'Oswald';
  font-weight: 400;
  font-size: 2.25rem;
  @media screen and (max-width: 500px){
    font-size: 1.75rem;
  }
  letter-spacing: -0.0625rem;
  text-transform: uppercase;
  @media screen and (min-width: 992px){

    width: 48.375rem;
    max-width: 45%;
  }
  z-index: 1;
  position: relative;
  &.text__brand{
    @media screen and (min-width: 992px){
      max-width: 60rem;
      width: 52.5%;
      margin-bottom: 1rem;
    }
    @media screen and (max-width: 991px){
      // margin-bottom: 2rem;
    }

  }
}
.about__column--left{
  position: absolute;
  bottom: 0;
  top: auto;
  right: 0;
  left: auto;
  .about__textTop--left{
    font-family: 'Oswald';
    font-size: 2.25rem;
    @media screen and (max-width: 500px){
      font-size: 1.75rem;
    }
    @media screen and (min-width: 992px){
      width: 47vw;
      max-width: 44.25rem;
    }
    letter-spacing: -0.0625rem;
    text-transform: uppercase;
    z-index: 1;
    position: relative;
    text-align: right;
  }
  img{
    z-index: 0;
    margin-right: 0;
    margin-left: auto;
    display: block;
    margin-bottom: 6.75rem;
    max-width: 32.9375rem;
    width: 100%;
  }
}
@media screen and (min-width: 992px){
  span.break{
    display: block;
  }
}
@media screen and (max-width: 991px){
  .about__column--left{
    position: relative;
  }
  span.break{
    display: inline-block;
  }
}
.page__wrapper--top .page__subPageTop{
  position: relative;
}

.page__subAssets{
  @media screen and (min-width: 992px){

    padding-bottom: 8rem;
  }
  @media screen and (max-width: 1400px) and (min-width: 992px){
    padding-bottom: 1.25rem;

  }


  display: flex;
  .dummy{
    min-width: 45rem;
    min-height: 45rem;
    display: block;
  }
  img{
    display: none;
  }
  .list{
    @media screen and (min-width: 992px){

    ul{
      li{
        position: relative;
        &:before{
          content: '';
          position: absolute;
          @media screen and (max-width: 1400px){
            transform: scale(0.85);
            margin-top: -1.125rem;
          }
        }
        min-height: 9.6875rem;
        @media screen and (max-width: 1400px){
          min-height: 12rem;
        }
        h3{
          font-family: 'Oswald';
          font-size: 1.875rem;
          font-weight: bold;
          font-style: italic;
          color: $orange;
          margin-left: 5.5rem;
          text-transform: uppercase;
        }
        p{
          font-size: 0.9375rem;
          margin-top: 0.375rem;
        }
      }
      h1{
        margin-bottom: 1rem;
      }
      li:nth-child(1){
        left: -5rem;
        margin-top: -1rem;
        &:before{
          background: transparent url("../../public/img/about/1.png") no-repeat;
          top: -1.625rem;
          left: -18.75rem;
          right: 0;
          bottom: 0;
          z-index: 1;
          height: 100%;
          @media screen and (max-width: 1400px){
            margin-left: -5.75rem;
          }
        }
        p{
          width: 34.125rem;
          height: 4.625rem;
        }
      }
      li:nth-child(2){
        margin-left: 13.75rem;
        @media screen and (max-width: 1400px){
          margin-top: 0;
        }
        margin-top: 1.5rem;
        &:before{
          background: transparent url("../../public/img/about/2.png") no-repeat;
          top: -1.75rem;
          left: -27.25rem;
          right: 0;
          bottom: 0;
          z-index: 1;
          height: 100%;
          @media screen and (max-width: 1400px){
            margin-left: -6.5rem;
          }
        }
        p{
          width: 23.875rem;
          height: 3.75rem;
          margin-left: -40px;
          @media screen and (max-width: 1400px){
            // margin-top: -0.125rem;
          }
        }
      }
      li:nth-child(3){
        margin-top: 1.5rem;
        @media screen and (max-width: 1400px){
          margin-top: 0;
        }
        margin-left: 2.75rem;
        &:before{
          background: transparent url("../../public/img/about/3.png") no-repeat;
          top: -2.25rem;
          left: -10.625rem;
          right: 0;
          bottom: 0;
          z-index: 1;
          height: 100%;
          @media screen and (max-width: 1400px){
            margin-left: -4.75rem;
            bottom: -5rem;
          }
        }
        p{
          width: 20.625rem;
          height: 3.75rem;
          margin-left: -1.875rem;
          @media screen and (min-width: 992px){

            // margin-top: -0.3125rem;
          }
        }
      }
      li:nth-child(4){
        margin-top: 1.5rem;
        margin-left: 10rem;
        @media screen and (max-width: 1400px){
          margin-top: 0;
        }
        &:before{
          background: transparent url("../../public/img/about/4.png") no-repeat;
          top: -2rem;
          left: -32.25rem;
          @media screen and (max-width: 1400px){
            left: -39.5rem;
          }
          right: 0;
          bottom: 0;
          z-index: 1;
          height: 100%;
        }
        p{
          width: 29.375rem;
          height: 3.75rem;
          // margin-top: -0.25rem;
          margin-left: -4.375rem;

        }
      }
      li:nth-child(5){
        margin-top: 1rem;
        margin-bottom: -3rem;
        @media screen and (max-width: 1400px){
          margin-top: -1.5rem;
          margin-bottom: 0;

        }
        margin-left: -3.125rem;

        &:before{
          background: transparent url("../../public/img/about/5.png") no-repeat;
          top: -2rem;
          left: -13.5rem;
          @media screen and (max-width: 1400px){
            left: -18.25rem;
          }
          right: 0;
          bottom: 0;
          z-index: 1;
          height: 100%;
        }
        p{
          margin-left: -3.75rem;
          width: 41.875rem;
          height: 4.375rem;
          // margin-top: -0.25rem;

        }
      }
    }
  }
    @media screen and (max-width: 991px){
      li{
        position: relative;
        margin-top: 2rem;
        margin-bottom: 2rem;
        h3{
          margin-bottom: 0.5rem;
          font-family: 'Oswald';
          font-size: 1.875rem;
          font-weight: bold;
          font-style: italic;
          color: $orange;
          text-transform: uppercase;
        }
        p{
          font-size: 0.9375rem;
        }
      }

    }
  }
  @media screen and (max-width: 991px){

    margin-left: auto;
    margin-top: -5rem;
    img{
      max-width: 50vw;
      max-height: 50vw;
      align-self: center;
      display:block;

    }
    .dummy{
      display: none;
    }
    .list{
      margin-left: 1.5rem;
    }
  }
  @media screen and (max-width: 767px){
    display: block;
    margin-left: auto;
    margin-right: auto;
    img{
      max-width: 100%;
      max-height: 100%;
    }
    .list{
      margin-left: 0;


    }

  }
  @media screen and (max-width: 1120px) and (min-width: 992px){
    transform: scale(0.9);
      .list ul li p{
        font-size: 1rem;
      }
      .list ul{
        li:nth-child(4){

          p{
            width: 31.5rem;
          }
        }
        li:nth-child(5){

          p{
            margin-left: -5rem;
            width: 45rem;

          }

        }
      }
    }
  }
