.page__cms {
  @media screen and (min-width: 992px){
    margin-top: 1.75rem;
  }
  background-image: url(img/subpage/bg.jpg);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: auto 37rem;
  @media screen and (max-width: 991px){
    background-image: none!important;
  }
}
.page__realizations{
  background-size: auto 30.5rem;

}
.page__subPageTop {
    margin-bottom: 2.6rem;
    @media screen and (max-width: 767px){
      margin-bottom: 2rem;
    }
}

.page__added{
  p{
    color: white;
  }
  & .page__section_light p{
    color: $black;
  }
}
body{
  background-color: $black;
}
.page__realizations {
	background-image: url(img/realizacje/bg.jpg);
}
.page__production {
	background-image: url(img/produkcja/bg.jpg);

}
.page__individualOrders  {
	background-image: url(img/orders/bg.jpg);

}
.page__coop{
    background-image: url(img/coop/bg.jpg);

}
.page__opinion{
    background-image: url(img/opinions/bg.jpg);

}
.page__about{
  @media screen and (max-width: 1400px){
    background-position: 40% top;

  }

  background-image: url(img/about/bg.jpg);
}
.page__subpageTitel h1 {
    font-size: 3rem;
    text-transform: uppercase;
    font-family: 'Oswald';
    text-align: left;
}
.page__subPageTitle h2 {
    font-weight: 600;
    text-transform: none;
    font-size: 3.750rem;
    text-align: left;
}
.page__subPageText {
    max-width: 40rem;
}
.page__subPageTitle {
    margin-bottom: 1.5rem;
}

.page__subPageText .text__paragraph {
    margin-bottom: 3rem;
}
.section_title {
    font-size: 3rem;
    text-transform: uppercase;
    font-weight: 800;
}
.page__subPageTitle h2{
  font-family: 'Roboto';
  .page__about &{
    font-family: 'Oswald';

  }
}
@media screen and (min-width: 2000px){
.page__cms {
    background-size: contain;
  }
}
@media (pointer: coarse) and (max-width: 1200px) {

}
@media screen and (max-width: 1400px){

}
@media screen and (max-width: 991px){
.page__cms {
    padding-top: 0.1rem;
}
.page__bredcrapms {
    margin: 0.5rem 0rem;
}
.page__subpageTitel h1 {
    font-size: 2rem !important;
}
.page__subPageTitle h2{
  margin-top: 1.5rem;
    font-size: 1.75rem !important;
}
.page__subPageText {
     max-width: 100%;
}
}
@media screen and (max-width: 767px){

}
@media screen and (max-width: 414px){

}
@media screen and (max-width: 320px){

}
