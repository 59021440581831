@if ( $mm_opt_listviews )
{
	.mm-listview,
	.mm-listitem
	{
		list-style: none;
		display: block;
		padding: 0;
		margin: 0;
	}
	.mm-listview,
	.mm-listitem,
	.mm-listitem:after,
	.mm-listitem .mm-btn_next,
	.mm-listitem .mm-btn_next:before
	{
		border-color: inherit;
	}
	.mm-listview
	{
		font: inherit;
		line-height: $mm_btnSize - ( $mm_listitemPadding * 2 );
	}
	.mm-panels > .mm-panel
	{
		> .mm-listview
		{
			margin: $mm_panelPadding ( -$mm_panelPadding );
		}
		> .mm-listview:first-child,
		> .mm-navbar + .mm-listview
		{
			margin-top: -$mm_panelPadding;
		}
	}


	//	Listitems
	.mm-listitem
	{
		position: relative;

		@include mm_border_bottom;
		&:after
		{
			left: $mm_listitemIndent;
		}

		a,
		a:hover
		{
			text-decoration: none;
		}

		> a,
		> span
		{
			@include mm_ellipsis;

			color: inherit;
			display: block;
			padding: $mm_listitemPadding $mm_listitemPadding $mm_listitemPadding $mm_listitemIndent;
			margin: 0;
		}

		// Open submenu
		@if ( $mm_opt_buttons )
		{
			.mm-btn_next
			{
				background: rgba( 3, 2, 1, 0 );
				padding: 0;
				position: absolute;
				right: 0;
				top: 0;
				bottom: 0;
				z-index: 2;

				@include mm_border_left;

				&:not( .mm-btn_fullwidth )
				{
					width: $mm_subopenWidth;

					+ a,
					+ span
					{
						margin-right: $mm_subopenWidth;
					}
				}
			}
			.mm-btn_fullwidth
			{
				&:before
				{
					border-left: none;
				}

				+ a,
				+ span
				{
					padding-right: $mm_subopenWidth;
				}
			}
		}
	}

	//	Dividers
	@if ( $mm_opt_listviews_divider )
	{
		.mm-listitem_divider
		{
			@include mm_ellipsis;

			font-size: $mm_dividerFontSize;
			text-transform: uppercase;
			padding: ($mm_listitemPadding / 2) $mm_listitemPadding ($mm_listitemPadding / 2 ) $mm_listitemIndent;

			//	enable empty dividers
			&:after
			{
				content: ' ';
				display: none;
				position: static;
				display: inline-block;
			}
		}
	}

	//	Spacers
	@if ( $mm_opt_listviews_spacer )
	{
		.mm-listitem_spacer
		{
			padding-top: $mm_btnSize;

			@if ( $mm_opt_buttons )
			{
				> .mm-btn_next
				{
					top: $mm_btnSize;
				}
			}
		}
	}
}
