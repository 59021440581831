.page__darkName{
    font-weight: 400;
    font-size: 3.25rem;
    font-family: 'Oswald';

}
.about__titl {
  font-weight: 300;

}
.about__brandName{
    font-weight: 600;
    font-size: 6rem;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 2rem;
    font-family: 'Oswald';
}
.about__content {
    display: flex;
    justify-content: space-between;
}
.about__left {
  @media screen and (min-width: 992px){
    width: 40%;
    max-width: 40vw;
  }

}
.about__right {
  @media screen and (min-width: 992px){

    width: 49%;
    max-width: 40vw;
  }

}
.about__text {
  @media screen and (min-width: 992px){

    // max-width: 35rem;
  }
}
& {
    background-image: url(img/about/bg_main.jpg);
    background-size: cover;
    position: relative;
    overflow: hidden;
    @media (max-width: 991px){

      &::before{
        content: '';
        background-color: rgba(black, 0.25);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
}
.about__wrap {
    position: relative;
    margin: 0 7.2rem;
}

.about__content {
    display: flex;
    z-index: 1;
    position: relative;
    margin-top: 2rem;
}
.about__backLogo {
    position: absolute;
    z-index: 0;
    width: 100%;
    margin-top: -2rem;
}
.about__backLogo svg {
    fill: transparent !important;
    // stroke: #fff !important;
    stroke-linecap: round;
    width: 100%;
    height: auto;
    #_2488050303376{
      stroke: #fff !important;
      // stroke-width: 5px;

    }
}
.about__backLogo svg .fil0 {
    // fill: #d45f5f00;
    // stroke-width: 17;
    // /* stroke-dasharray: 1.11528e+06, 1.11528e+06; */
    // /* stroke-dashoffset: 10; */
    // stroke: rgba(255, 255, 255, 0.26);
}
.about__textLarge {
    font-size: 2.25rem;
    text-transform: uppercase;
    line-height: 1.2;
    font-family: 'Oswald';
}
.about__link {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin-top: 1rem;
}
.about__wrap {
    position: relative;
    margin: 4rem 0rem 4rem 0rem;
}

.about__link a{
    display: flex;
    align-content: center;
    align-items: center;
    font-family: 'Oswald';

}
.opinions__sliderLink .btn-long {
    padding: 0.7rem 4rem;
}
body.noTouchscreen &{
  .about__link:hover a, .about__link a:hover{
    opacity: 1;
  }
}
.text__paragraph{
  color: white;
}
@media screen and (max-width: 1400px){

}
@media screen and (max-width: 991px){
    .about__wrap {
        margin: auto;
    }
.about__content {
    display: block;}
.about__left,.about__right {
    width: 100%;
}
.about__textLarge {

  font-weight: 300;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 1.375;
  text-transform: none;
  margin-top: 1.75rem;
}
.about__right .about__titl{
  display: none;
}
}
@media screen and (max-width: 767px){
.about__brandName {
    font-size: 4rem;
}
.page__darkName {
    font-size: 2rem;
}

}
@media screen and (max-width: 414px){

.about__brandName {
    font-size: 2rem;
}
.page__darkName {
    font-size: 1.5rem;
}


.section__title h2{ font-size: 2rem;}
}
@media screen and (max-width: 320px){

}
@media screen and (max-width: 500px){
  .about__brandName {
     font-size: 2.5rem;
 }
}
