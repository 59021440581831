.page-list a{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  background-color: $gray_lighter_price-steel;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  font-family: 'Oswald';
  font-size: 0.875rem;
  color: white;
  a{
    font-size: 0.875rem;
  }
}
.page-list{
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.page-list a.prev svg{
  -webkit-transform: rotate( -180deg );
    -ms-transform: rotate( -180deg );
    transform: rotate( -180deg );
    left: -0.125rem;
    position: relative;
}


.page-list a.prev, .page-list a.next{
  width: 2.5rem;
  height: 2.5rem;
}
.page-list a.prev:not(.disabled), .page-list a.next:not(.disabled){
  background-color: $black;

}
.page-list a.next.disabled, .page-list a.prev.disabled{
  background-color: $gray_lighter_price-steel;
  pointer-events: none;
  svg{
    #pusty{
      opacity: 1;
      transition: 0.3s;
    }
    #pelny{
      opacity: 0;
      transition: 0.3s;
    }
  }
}
.page-list a svg{
  width: 1rem;
  height: 1.25rem;
  position: relative;
  top: 0.25rem;
  left: 0.125rem;
  fill: white;
}
.page-list a.current{
  background-color: $black;
  color: white;
  pointer-events: none;

}
.page-list a.current{
    color: white;
}
a:not(.previous):not(.next) {
    letter-spacing: 0;
}
