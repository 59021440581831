.footer__socialIcon svg, .footer__socialIcon svg .fil0 {
    width: 1.25rem;
    height: 1.25rem;
    fill: $orange;
}
svg.logo{
  opacity: 0;
  transition: all 0.125s;
}
svg.logo.-animate {
  opacity: 1;
	-webkit-animation-name : fadeout;
	-moz-animation-name : fadeout;
	-o-animation-name : fadeout;
	animation-name : fadeout;

	-webkit-animation-duration: 1s;
	-moz-animation-duration: 1s;
	-o-animation-duration: 1s;
	animation-duration: 1s;

	-webkit-animation-timing-function : cubic-bezier(.1,1,.6,1);
	-moz-animation-timing-function : cubic-bezier(.1,1,.6,1);
	-o-animation-timing-function : cubic-bezier(.1,1,.6,1);
	animation-timing-function : cubic-bezier(.1,1,.6,1);

	-webkit-animation-fill-mode : forwards;
	-moz-animation-fill-mode : forwards;
	-o-animation-fill-mode : forwards;
	animation-fill-mode : forwards;

	-webkit-animation-delay : 0s;
	-moz-animation-delay : 0s;
	-o-animation-delay : 0s;
	animation-delay : 0s;
}
.footer__socialLinks a {
  display: inline-flex;
  align-content: center;
  align-items: center;
  width: auto;
  margin-right: 1.1rem;
	text-transform: uppercase;
	font-size: 0.75rem;
  font-family: 'Oswald';
  color: white;
}
.footer__socialIcon {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.5rem;
}
.footer__wrap {
    text-align: center;
    position: relative;
}
.footer__socialLinks {
    position: absolute;
    top: 1rem;
}
.footer__text p {
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
}
.footer__separator {
    padding: 0rem 1rem;
}
.text__brand, .text__brand p{
  color: $orange;
  strong{
    font-weight: 900;
  }
}

@media (pointer: coarse) and (max-width: 1200px) {
  .footer__socialLinks {
    position: relative;
    top: -1rem;
  }
}
@media screen and (max-width: 1400px){

}
@media screen and (max-width: 991px){
  .footer__socialLinks {
      position: relative;
      top: -1rem;
  }
}
// @media screen and (max-width: 767px){
//
// }
// @media screen and (max-width: 414px){
//
// }
// @media screen and (max-width: 320px){
//
// }
&{
  background-image: url(img/footer/bg.jpg);
  background-color: $black;
  padding-top: 4rem;
  margin-top: 0;
  .h3, .h3 a, .block-contact-title{
    color: white !important;
    font-family: 'Oswald';
    font-size: 1.5rem;
  }
  .row{
    margin-left: 0;
    margin-right: 0;

  }
}
.footerNav__logo svg{
  margin-bottom: 3.6rem;
  fill: $orange;
  margin-right: auto;
  margin-left: auto;
  display: block;
  width: 100%;
  max-height: 10rem;
  height: 100%;
}
ul li p, ul li a, .links ul > li a {
  color: $gray_presta;

    font-size: 0.875rem;
    font-weight: normal;
    @media (max-width: 991px){
      color: $white_collapse;
      font-weight: bold;
    }
}
.links ul{
  background-color: transparent;
}
@media (max-width: 991px){
.contact-info{
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
    .h3, .title {
      text-align: left;
    }
    .title.not-link{
      cursor: auto;
      border-bottom: 0px solid;
      margin-bottom: -1rem;
    }
    .links .navbar-toggler{
      color: $white_collapse;
    }
  }
  .add, .remove{
    margin-top: 0.125rem;
  }
  .links ul > li{
    border-bottom: 0px solid;
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .links ul{
    margin-top: 0.5rem;
    margin-bottom: 0.875rem;
  }
}
