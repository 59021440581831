// STRUKTURA

// ################ DEFAULT STRUCTURE SETTINGS ###########
.page__main{
	background-color:white; //dla mmenu
}
.section__text{
	font-size: 1.5rem;
	font-weight: 300;
	text-align: center;
}
.page__section{
	width:100%;
}
.page__container{
	width: 100%;
	max-width: $container-width + $container-padding + $container-padding; //double padding added because of box-sizing: border-box
	margin: 0 auto;
	padding-left: $container-padding;
	padding-right: $container-padding;
}
.page__container--big, #category .container, #new-products .container, #best-sales .container{
	width: 100%;
	max-width: $container-big-width + $container-padding + $container-padding; //double padding added because of box-sizing: border-box
	margin: 0 auto;
	padding-left: $container-padding;
	padding-right: $container-padding;
}

// fixed footer

.page__main{
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.page__outside-footer{
	flex: 1 0 auto;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
}





// wrapper mmenu - ukrywaj zanim sie zainicjalizuje

#mobile-menu-hide-before-init{
	display: none;
}
