// @import 'plugins/hamburgers/hamburgers';


// @import 'plugins/normalize';

// base
@import 'base/elements';
@import 'base/mixins';
@import 'base/variables';
@import 'base/settings';
@import 'base/structure';
@import 'base/00_base';
@import 'base/animations';
@import 'subpage/base';
@import 'classes';
#preloader{
  @import 'section/preloader';
}
@import 'components/slider';
header#header {
  @import 'section/01_nav';
}
.section__intro {
  @import 'section/02_intro';
}
// .section__newPoducts {
//   @import 'section/03_products';
// }
 .section__articles{
   @import 'section/04_articles';
 }
 .section__about {
   @import 'section/05_about';
 }
 .section__opinions {
   @import 'section/06_opinions';
 }
// .section__footerNav {
//   @import 'section/08_footerNav';
// }
.section__newsletter {
  @import 'section/07_newsletter';
}
#app{ //vue styles
  position: relative;
  width: 100%;
  @import 'section/0_special_product_app';
}
.opinions__wraper{
  @import 'subpage/opinions/opinions__content';
}
.page__production {
  @import 'subpage/produkcja/produkcja_slider';
}
.page__realizations {
  @import 'subpage/realizacje/realizacje__content';
}
.realizations__map{
  @import 'subpage/realizacje/realizacje__map';
}
.orders__qestions{
  @import 'subpage/orders/questions';
}
.page__coop {
  @import 'subpage/coop/timeLine';
}
.page__coop {

  @import 'subpage/coop/questions';
}
.page__about {

  @import 'subpage/about/page__about';
}
.about__middle {
  @import 'subpage/about/middle__about';
}
.page__about--bottom{
  @import 'subpage/about/bottom__about';
}
@import 'subpage/back_svg';
@import 'subpage/breadcrumps';
//
// // components
@import 'components/backtotop';
@import 'components/cookies';
@import 'temp';
@import 'section/product_miniature';

.bc_special-wrapper{
  @import 'special';
}

.section__contact{
  @import 'section/09_contact';
}

#blockcart-modal.modal {
  @import 'section/basket_modal';
}
#cart{
  @import 'section/basket';
}
#packages{
  @import 'section/packages';
}
.featured-products{
  @import 'section/new-products';

}
.bestseller-products{
  @import 'section/bestseller';
}
.product-gallery__wrapper{
  @import 'section/gallery';
}
.modal.quickview{
  @import 'section/quick-modal'
}
.page-product{
  @import 'section/product-page';
}
#category, #new-products{
  @import 'section/category-page';
}
.pagination{
  @import 'components/pagination';
}
#ask_popup{

  @import 'section/product_contact';
}
#checkout{
  @import 'section/checkout';
}
.footer-container {
  @import 'section/10_footer';
}
#order-confirmation{
  @import 'section/order-confirmation';
}
#js-product-list-top{
  @import 'section/sort-by';
}
@import 'fa/fontawesome';
@import 'fa/fa-brands';
@import 'fa/fa-light';
@import 'fa/fa-regular';
@import 'fa/fa-solid';

@import 'components/menu_mobile';
@import 'components/popup_form';
@import 'components/not_found';
