
@mixin selection($background, $text){

    /* WebKit/Blink Browsers */
    ::selection {
      background: $background; 
      color: $text;
    }
    
    /* Gecko Browsers */
    ::-moz-selection {
      background: $background; 
      color: $text;
    }

}

//PLACE FOR @FONT-FACE DECLARATIONS