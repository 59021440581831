&{
  background-color: $black;
  color: white;
  .question__content{
    background-color: $black;
    @media screen and (min-width: 992px){
      height: auto;
    }
  }
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  @media screen and (min-width: 992px){
    height: auto;
  }
	@media screen and (max-width: 991px){
    padding-top: 1rem;
    padding-bottom: 1em;
		display: block;
		max-height: 100%;
		max-width: 100%;
	}
}
.question__content{
  @media screen and (min-width: 992px){
  }
}
.product__inLineInputs {
}
#product__form{
	margin-left: auto;
	margin-right: auto;
}
.btn{
	padding: 0.8rem 2.25rem;
}
.section__title h2{
	text-align: center;
	margin-bottom: 1rem;
	@media screen and (max-width: 991px){
		font-size: 1.75rem;
	}
}
#product__form input, #product__form textarea, .orders__qestionsForm input, .orders__qestionsForm textarea {
  width: 100%;
  background: transparent;
  border: 1px solid #fff;
  text-align: center;
  padding: 1rem;
  color: #fff;
  font-family: 'Oswald', sans-serif;
  font-size: 1rem;
  margin-bottom: 1rem;
	@media screen and (max-width: 991px){
		padding: 0.625rem;
	}
}
input#product__form__reg {
  width: auto;
}
#product__form input{
	margin-left: 0.5rem;
  margin-right: 0.5rem;
}
#product__form .product__inLineInputs {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
#product__form textarea {
  max-width: 100%;
  min-width: 100%;
	margin-left: 0rem;
	margin-right: 1rem;
}
.product__formChboxWrap {
  margin-left: auto;
  margin-right: auto;
  display: flex;
}
.product__formChboxWrap .page__form-check-label {
  margin-left: 0px;
	color: white;
	text-align: left;
}
.page__ch-mask input + .checkmark{

	border: 1px solid white;
}
.page__ch-mask input:checked + .checkmark:after{
	color: white;
}
.product__formChboxWrap #product__form__reg {
  margin-left: -13px;
}
.product__formBtnWrap {
  text-align: center;
	margin-top: 0.25rem;
}

#product__form ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #fff;
	opacity: 1;

}
#product__form ::-moz-placeholder { /* Firefox 19+ */
  color: #fff;
	opacity: 1;
}
#product__form :-ms-input-placeholder { /* IE 10+ */
  color: #fff;
	opacity: 1;

}
#product__form :-moz-placeholder { /* Firefox 18- */
  color: #fff;
	opacity: 1;
}
#product__form .page__form-check-label {
  margin-left: 0px;
  top: 3px;
}
.product__inLineInputs {
  display: block;
  margin-right: 0.5rem !important;
}
.product__inLineInputs input{
  margin-right: 0;
}
.product__formWrap input {
  margin-left: 0rem;
  margin-right: 0rem;
}

@media screen and (max-width: 991px){
  .question__content{
    max-width: 100%;
    max-height: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 991px){
	.page__form-check-label{
		font-size: 0.65rem;
	}
}
.ask_popup_close{
	top: 1rem;
	right: 2rem;
	position: absolute;
	font-size: 2rem;
}
