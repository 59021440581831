@import url('https://fonts.googleapis.com/css?family=Oswald:200,300,400,500,600,700&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,600,700&subset=latin-ext');

*{
	box-sizing:border-box;
}
ul,p{
	margin:0px;
}
ul{
	list-style:none;
	padding:0px;
}
a, a:visited{
	text-decoration:none;
	text-decoration: none;
}
input:focus,textarea:focus,select:focus {
    outline-offset: 0;
    outline: none;
}
h1,
h2,
h3,
h4,
p,
ol,
ul {
    margin: 0;
    padding: 0;
}

button,a {
    color: inherit;
}
.btn {
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    background: $orange;
    display: inline-block;
    border-radius: 3rem;
		font-size: 0.875rem;

    cursor: pointer;
		font-family: 'Oswald';
		color:#fff;
		&:hover, &:focus{
			color: white;
		}
}
.page__section_dark .btn {

 	color:#000;
}


.btn.btn-dark {

    background: #000;
    color: $orange;
}
.btn.btn-light{
    background: #fff;
    color: #000;
}
.btn.btn-long {
    padding: 1rem 3rem;
}
.btn.btn-products {
    color: #fff;
}
.btn.btn-primary{
	display: inline-flex;
	align-items: center;
}
img {
    max-width: 100%;
}


h1,
h2,
h3,
h4 {
    font-size: inherit;
}
h2 {
    font-size: 3rem;
    text-transform: uppercase;
		font-family: 'Oswald', sans-serif;
		font-weight: 700;
		// @media (max-width: 500px){
		// 	font-size: 2rem;
		// }
}
button, input[type="reset"], input[type="button"], input[type="submit"] {
    overflow: visible;
    border: 0;
    font: inherit;
    -webkit-font-smoothing: inherit;
    letter-spacing: inherit;
    background: none;
    cursor: pointer;
    padding:0px; //dodano
}
fieldset{
  border:0px;
  padding:0px;
}

button,a {
  transition: color .3s ease,background-color .3s ease,border-color .3s ease,opacity .3s ease;
}
textarea{
  font-family: inherit;
  font-size:inherit;
  color:inherit;
}

img{
  vertical-align:text-bottom;
}

body{
  cursor:default;
  margin: 0px;
}
html {
    font-family: 'Oswald', sans-serif;
}

input, textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

input[type=checkbox]
{
  -webkit-appearance:checkbox;
}


table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

// poprawka normalize, psuło fixed footer

html{
    line-height: initial;
}

date{
  display: inline-block;
}

// usun niebieska ramke

*:focus {
  outline:0;
}
button:focus {
    outline: none;
}

/* ukryj guzik zamykania w tooltipie */
.gm-style-iw + div {display: none;}

.gm-style-iw{
  text-align: center;
}
form {
    margin-bottom: 0em;
}




p, .text__paragraph {
    font-weight: 300;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    line-height: 1.375;

}

.page__ch-mask input {
    z-index: 2;
}
.page__ch-mask input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    margin-top: 5px;
    z-index: 5;
}
.page__ch-mask .checkmark {
    margin-right: 0.5rem;
}
.page__ch-mask input:checked + .checkmark {
    width: 15px;
    height: 15px;
    overflow: visible;
    margin-right: 0.5rem;
    pointer-events: none;
}
.page__ch-mask input + .checkmark {
    width: 15px;
    height: 15px;

    background-position: 0px 0px;
    z-index: 1;
    border: 1px solid;
}
.page__ch-mask .checkmark {
    display: block;
    position: relative;
    cursor: pointer;
    left: -1px;
    top: 3px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-position: 0px 0px;
}
.page__ch-mask input:checked + .checkmark:after {
    content: '';
    background: transparent;
    width: 15px;
    height: 7px;
    overflow: hidden;
    background-position: -15px 0px;
    border-left: 2px solid;
    transform: rotate(-45deg);
    border-bottom: 2px solid;
    position: relative;
    z-index: 3;
    top: -3px;
    left: 2px;
    display: block;
    }

.page__form-check-label {
    font-size: 0.85rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}
.page__ch-mask.newsleater__chwrap {
    top: 10px;
		position: relative;
    width: 2rem;
    cursor: pointer;
}
.page__termsChbox {
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    position: relative;
    text-align: center;
    margin-bottom: 1rem;
}
.page__termsWrap {
    margin-left: auto;
    margin-right: auto;
}
.page__form-check-label {
    font-size: 0.8rem;
    font-family: 'Roboto', sans-serif;
		font-weight: 300;
    margin-left: 1.5rem;
    position: relative;
    z-index: 1;
    cursor: pointer;
}
.page__section_padding {
    padding: 4.3rem 0rem;
}
// .page__container {
//     width: 100%;
//     max-width: 103.75rem;
// 	  margin: 0 auto;
//     padding-left: 2rem;
//     padding-right: 2rem;
// }
.page__section_dark{
    background-color:  $black ;
    color: #fff;
}
.page__section_light{
    background-color: #fff;
    color: #151517;
}
.page__section_brand{
    background-color: $orange;
    color: #151517;
}
.text__brand{
    color: $orange;
}
.section__title {
    text-align: center;
}
.page__linkIcon {
    width: 58px;
    height: 58px;
    position: relative;
    display: inline-block;
		@media (max-width: 767px)
		{
			height: 40px;
			width: 40px;
		}
}

.page__linkIcon svg {
    display: block;
    text-indent: -9999px;
    width: 100%;
    height: 100%;
    transform: rotate(-21deg);
    transition: 0.3s;
		#pelny{
			opacity: 0;
			transition: 0.3s;
		}
		#pusty{
			opacity: 1;
			transition: 0.3s;
		}
		.fil1 {fill:#EF6D1E}
    .fil0 {fill:#FEFEFE}
}
body.noTouchscreen{
	a:hover .page__linkIcon svg, a:hover.page__linkIcon svg{
	    transition: 0.3s;
	    transform: rotate(0deg) translateX(-0.75rem);
			@media screen and (max-width: 767px){
				transform: rotate(0deg) translateX(-0.5rem);

			}
			#pelny{
				opacity: 1;
			}
			#pusty{
				opacity: 0;
			}
	}
}
.-new {
    padding: 2rem 0rem;
}
.page__footerBGwrap {
    background-image: url(img/footer/bg.jpg);
    background-size: cover;
    background-color:  $black ;
}
.page__footerBGwrap .page__section_dark {
    background-color: transparent;
}

@media (pointer: coarse){
body {
    background: #000;
}
}
#backtotop{

    /* position*/
    position: fixed;
    right: 0.4rem;
    bottom: 5.8rem;



    /*visibility*/

    opacity: 0;
    pointer-events:none;


    /* przykladoe style*/
    // background-color:black;
    color: $orange;
    width: 3rem;
    height: 3rem;
    text-align: center;
    font-size: 2rem;
    transition:0.3s ease;
    cursor:pointer;
    z-index: 5;

}
#backtotop.visible{
        opacity: 1;
        pointer-events:auto;
    }
// @media screen and (max-width: $phone){
//     #backtotop.visible{
//         pointer-events: none;
//         opacity: 0;
//     }
// }

#backtotop .fas {
    margin-top: 6px;
}

/****** coookies ******/


.backtotop__back-top svg {
    width: 2rem;
    height: 2rem;
}
.backtotop__back-top svg .fil0{
     fill: $orange;
}
.backtotop__icon {
    position: relative;
    width: 0px;
    height: 0px;
		top: 0.8rem;
    left: 0.2rem;
}
.brighttheme.ui-pnotify-container{
	background: $orange;
	color: white;
	border: 1px solid $orange;

  }

  .brighttheme-error.ui-pnotify-container{
	background: $orange;
	color: white;
  }


@media (min-width: 501px){
	a[href^="tel:"]{
		pointer-events: none;
	}
}
@media screen and (max-width: 414px){
.page__container {
    padding-left: 1rem;
    padding-right: 1rem;
}
.btn.btn-long ,.btn{
    padding: 0.5rem 1rem;
}
}
@media screen and (max-width: 320px){

}
.page__subPageTop.page__marginSub{
  // margin-bottom: 8rem;
}
.page__bacground_animatedSvg{
	fill: transparent !important;
	stroke-width: 0px;
	stroke-linecap: round;
	width: 100%;
	height: auto;
	#_2488050303376{
		stroke: #fff !important;
	}
}
body.cookieopen .page__footer-fixed .page__footerBGwrap{
	@media (max-width: 991px){
		padding-bottom: 70px;
		background-color: $black ;
	}
}
body.cookieopen #backtotop{
	@media (max-width: 991px){
		bottom: 5rem;
	}
}
.page__subPageText .text__paragraph{
text-shadow: 6px 4px 5px black;
}
