&{
  position: relative;
  &::after{
    content: '';
    background-image: url('../../public/img/about/about__bottom_image.png');
    background-repeat: no-repeat;
    background-position: 28vw bottom;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    background-size: 72.875rem;
    @media screen and (max-width: 991px){
      position: relative;
      display: block;
      background-position: 60% bottom;
      background-size: auto;
      height: 35rem;
      @media screen and (max-width: 500px){
        background-size: 200%;
        height: 100vw;
      }
    }
  }
  padding-bottom: 0;
  .page__subPageTitle h2{
    font-family: 'Oswald';
    text-transform: uppercase;
  }
  .text__heading{
    font-family: 'Oswald';
    font-weight: 400;
    font-size: 2.25rem;
    @media screen and (max-width: 500px){
      font-size: 1.75rem;
    }
    letter-spacing: -0.0625rem;
    text-transform: uppercase;
    color: $orange;
    z-index: 1;
    position: relative;
  }
  .page__bottom--wrapper{
    z-index: 1;
    position: relative;
    .text__heading, p:not(:first-of-type){
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }

    @media screen and (min-width: 992px){
      max-width: 35rem;
    }
  }
  @media screen and (max-width: 991px){
    &::after{
      position: relative;
    }
  }
}
