.product-name, &#blockcart-modal .product-name{
  color: $black;
  font-family: 'Oswald';
  font-weight: bold;
  font-size: 1.375rem;
  text-transform: uppercase;
}
&#blockcart-modal .divide-right{
  border-right: 0px solid white;
}
&#blockcart-modal .modal-header{
  border-bottom: 0px solid transparent;
}
.product-image{
  margin-top: 1.5rem;
}
.product-price{
  color: $orange;
  font-family: 'Oswald';
  font-weight: bold;
  font-size: 1.375rem;
}
.modal-body{
  overflow: hidden;
  .black-content{
    background-color: $black;
    color: white;
    position: relative;
    @media (max-width: 767px){
      padding-top: 1rem;
    }
    p{
      color: white;
      font-size: 1rem;
      strong{ font-weight: 900;}
    }
    p.cart-products-count{
      font-size: 1.5rem;
      font-weight: 300;
    }
    &::after{
      content: '';
      position: absolute;
      top: -5rem;
      bottom: -10rem;
      left: 0;
      right: -2rem;
      z-index: 0;
      background-color: $black;
    }
    .cart-content{
      z-index: 1;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      p{
        margin-bottom: 0.25rem;
        margin-top: 0.25rem;
      }
      p.cart-products-count{
        margin-top: 0;
        margin-bottom: 1rem;

      }
      .cart-content-btn{
        margin-top: 2rem;
      }
    }
  }
  .col-md-5{
    background-color: white;
    position: relative;
    z-index: 999;
    @media (max-width: 767px){
      padding-bottom: 1rem;
    }
  }
}
.cart-content .cart-content-btn {
  @media screen and (min-width: 992px){
    display: flex;
    justify-content: space-between;
    .btn.btn-secondary{
      min-width: 15.5rem;
    }
  }
}
.close, .close span{
   color: white;
   opacity: 1;
}
#myModalLabel i{
  margin-right: 1.375rem;
  @media screen and (max-width: 991px){
    margin-right: 1rem;
  }
  width: 2.5rem;
  height: 2.5rem;
  font-size: 2.5rem;
}
.modal-title{
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}
.modal-header{
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.cart-content{
  padding-left: 1.5rem;
}
.black-content{
  p strong{
    margin-right: 0.325rem;
  }
}
.qty__basket{
  color: $black;
  strong{
   margin-right: 0.325rem;
   color: $black;
   font-weight: 700;
 }
}
.cart-content .cart-content-btn{
  @media screen and (max-width: 550px){
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
