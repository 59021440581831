& .section__title{
	margin-bottom: 2rem;
	color: white;
	display: flex;
	justify-content: center;
	text-align: center !important;

}

& {
  padding-top: 0.4rem;

	margin-top: 2rem;

}
.contact__inLineInputs {
  display: flex;
}
#contact{
	// max-width: 1100px;
	@media screen and (min-width: 992px){
		max-width: 72.5rem;
	}
	margin-left: auto;
	margin-right: auto;
}
.btn{
	padding: 0.8rem 2.25rem;
}
.contact__formWrap input,.contact__formWrap textarea {
  width: 100%;
  background: transparent;
  border: 1px solid #fff;
  text-align: center;
  padding: 1rem;
  color: #fff;
  font-family: 'Oswald', sans-serif;
  font-size: 1rem;
  margin-bottom: 1rem;
}
input#contact__reg {
  width: auto;
}
.contact__formWrap input:nth-child(2){
	margin-left: 1rem;
  margin-right: 1rem;
}
.contact__formMsg textarea {
  max-width: 100%;
  min-width: 100%;
}
.contact__formChboxWrap {
  margin-left: auto;
  margin-right: auto;
  display: flex;
}
.contact__formChboxWrap .page__form-check-label {
  margin-left: 0px;
	color: white;
	text-align: left;
}
.page__ch-mask input + .checkmark{

	border: 1px solid white;
}
.page__ch-mask input:checked + .checkmark:after{
	color: white;
}
.contact__formChboxWrap #contact__reg {
  margin-left: -13px;
}
.contact__formBtnWrap {
  text-align: center;
	margin-top: 0.25rem;
}

.contact__formWrap ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #fff;
	opacity: 1;

}
.contact__formWrap ::-moz-placeholder { /* Firefox 19+ */
  color: #fff;
	opacity: 1;
}
.contact__formWrap :-ms-input-placeholder { /* IE 10+ */
  color: #fff;
	opacity: 1;

}
.contact__formWrap :-moz-placeholder { /* Firefox 18- */
  color: #fff;
	opacity: 1;
}
.contact__formChboxWrap .page__form-check-label {
  margin-left: 0px;
  top: 3px;
}

@media screen and (max-width: 1400px){

}
@media screen and (max-width: 991px){

}
@media screen and (max-width: 991px){
	.contact__inLineInputs {
	  display: block;
	}
	.contact__formWrap input:nth-child(2) {
	  margin-left: 0rem;
	  margin-right: 0rem;
	}
	.section__title h2{
		text-align: center;
	}
}
@media screen and (max-width: 414px){

}
@media screen and (max-width: 320px){

}
