&{
  background-color: white;
}
#index &{
  padding: 2rem 0;
}
.add-product{
  @media screen and (min-width: 992px){
    position: absolute !important;
  }
  right: 0;
  bottom: 0;
  padding: 1rem 4rem;
  color: white !important;
  font-size: 0.875rem;
  &:focus, &:hover{
    color: white !important;
  }
}
.page__container{
  position: relative;
}
.head__desc{
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 2rem;
  margin-top: -0.625rem;

}
@media (max-width: 991px){
  .swiper-container{
    padding-bottom: 2rem;
  }
}
