@-webkit-keyframes dash {
  60%,70% {
    stroke-dashoffset: 0;
    fill-opacity: 1;
  }

  80% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }

  95% {
		stroke: white;
    stroke-dashoffset: -301;
    stroke-opacity: 0;
  }

  100% {
    fill-opacity: 1;
    stroke-dashoffset: -301;
		stroke-opacity: 0;

  }
}

@-o-keyframes dash {
  60%,70% {
    stroke-dashoffset: 0;
    fill-opacity: 1;
  }

  80% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }

  95% {
		stroke: white;
    stroke-dashoffset: -301;
    stroke-opacity: 0;
  }

  100% {
    fill-opacity: 1;
    stroke-dashoffset: -301;
		stroke-opacity: 0;

  }
}

@-moz-keyframes dash {
  60%,70% {
    stroke-dashoffset: 0;
    fill-opacity: 1;
  }

  80% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }

  95% {
		stroke: white;
    stroke-dashoffset: -301;
    stroke-opacity: 0;
  }

  100% {
    fill-opacity: 1;
    stroke-dashoffset: -301;
		stroke-opacity: 0;

  }
}


@keyframes dash {
  60%,70% {
    stroke-dashoffset: 0;
    fill-opacity: 1;
  }

  80% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }

  95% {
		stroke: white;
    stroke-dashoffset: -301;
    stroke-opacity: 0;
  }

  100% {
    fill-opacity: 1;
    stroke-dashoffset: -301;
		stroke-opacity: 0;

  }
}

@keyframes fadeout {
  100% {
    filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px);
  }

  75% {
    -webkit-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    filter: blur(10px);
  }

  25% {
    opacity: 1;
    -webkit-filter: blur(25px);
    -moz-filter: blur(25px);
    -o-filter: blur(25px);
    -ms-filter: blur(25px);
    filter: blur(25px);
  }

  0% {
    opacity: 0;
    -webkit-filter: blur(45px);
    -moz-filter: blur(45px);
    -o-filter: blur(45px);
    -ms-filter: blur(45px);
    filter: blur(45px);
  }
}
@-webkit-keyframes fadeout {
  100% {
    filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px);
  }

  75% {
    -webkit-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    filter: blur(10px);
  }

  25% {
    opacity: 1;
    -webkit-filter: blur(25px);
    -moz-filter: blur(25px);
    -o-filter: blur(25px);
    -ms-filter: blur(25px);
    filter: blur(25px);
  }

  0% {
    opacity: 0;
    -webkit-filter: blur(45px);
    -moz-filter: blur(45px);
    -o-filter: blur(45px);
    -ms-filter: blur(45px);
    filter: blur(45px);
  }
}
@-o-keyframes fadeout {
  100% {
    filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px);
  }

  75% {
    -webkit-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    filter: blur(10px);
  }

  25% {
    opacity: 1;
    -webkit-filter: blur(25px);
    -moz-filter: blur(25px);
    -o-filter: blur(25px);
    -ms-filter: blur(25px);
    filter: blur(25px);
  }

  0% {
    opacity: 0;
    -webkit-filter: blur(45px);
    -moz-filter: blur(45px);
    -o-filter: blur(45px);
    -ms-filter: blur(45px);
    filter: blur(45px);
  }
}

@-moz-keyframes fadeout {
  100% {
    filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px);
  }

  75% {
    -webkit-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    filter: blur(10px);
  }

  25% {
    opacity: 1;
    -webkit-filter: blur(25px);
    -moz-filter: blur(25px);
    -o-filter: blur(25px);
    -ms-filter: blur(25px);
    filter: blur(25px);
  }

  0% {
    opacity: 0;
    -webkit-filter: blur(45px);
    -moz-filter: blur(45px);
    -o-filter: blur(45px);
    -ms-filter: blur(45px);
    filter: blur(45px);
  }
}
