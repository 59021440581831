.page__bacground_animatedSvg svg{
	width: 100%;
    height: 100%;
}
.page__bacground_animatedSvg svg .fil0{
	fill: rgba(255, 255, 255, 0.2);
}
.page__bacground_animatedSvg {
    margin-bottom: -4rem;
}
.page__bacground_animatedSvg svg .fil0 {
    fill: transparent;
    stroke-width: 17;
    /* stroke-dasharray: 1.11528e+06, 1.11528e+06; */
    /* stroke-dashoffset: 10; */
    stroke: rgba(255, 255, 255, 0.26);
}




@media (pointer: coarse) and (max-width: 1200px) {

}
@media screen and (max-width: 1400px){

}
@media screen and (max-width: 991px){
.page__bacground_animatedSvg {
    margin-bottom: 0rem;
}
.page__bacground_animatedSvg svg  {
    display: none;
}
}
@media screen and (max-width: 767px){


}
@media screen and (max-width: 414px){

}
@media screen and (max-width: 320px){

}
