@import 'variables';

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  src: url('webfonts/fa-brands-400.eot');
  src: url('webfonts/fa-brands-400.eot?#iefix') format('embedded-opentype'),
  url('webfonts/fa-brands-400.woff2') format('woff2'),
  url('webfonts/fa-brands-400.woff') format('woff'),
  url('webfonts/fa-brands-400.ttf') format('truetype'),
  url('webfonts/fa-brands-400.svg#fontawesome') format('svg');
}

.fab {
  font-family: 'Font Awesome 5 Brands';
}
