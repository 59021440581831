.newsletter_inputWrap {
    display: flex;
}
.newsletter_inputWrap input {
    width: 36rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    background: transparent;
    border: none;
    border-bottom: 2px solid white;
    font-family: 'Oswald', sans-serif;
    font-weight: 600;
    font-size: 1rem;
    padding: 0.5rem 0.5rem;
    color: white;
    margin-top: 2rem;
}
.newsletter_inputWrap ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: white;
  opacity: 1;
}
.newsletter_inputWrap ::-moz-placeholder { /* Firefox 19+ */
  color: white;
  opacity: 1;
}
.newsletter_inputWrap :-ms-input-placeholder { /* IE 10+ */
  color: white;
  opacity: 1;
}
.newsletter_inputWrap :-moz-placeholder { /* Firefox 18- */
  color: white;
  opacity: 1;
}

.newsletter__form .page__form-check-label {
    top: -5px;
}
.newsletter__form .freshmail__send {
    margin-right: auto;
    margin-left: auto;
    display: block;
}
.btn{
  padding: 0.9rem 2.5rem;
}
.section__title, .section__title h2{
  text-align: center;

  margin-bottom: 0.75rem;
  text-align: center !important;
}
.page__ch-mask input + .checkmark{
  top: 0.75rem;
}
.page__ch-mask input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    margin-top: 5px;
    z-index: 5;
    left: 1.25rem;
    top: 0.5rem;
}
.page__ch-mask.newsletter__chwrap{
  position: relative;
}
.page__form-check-label {
	text-align: left;
}
.small-mobile{
  display: inline-block;
}
@media (max-width:475px){
  .hide-mobile{
    display: none;
  }
  .small-mobile{
    display: block;
    font-size: 2rem;
    margin-top: 0.75rem;
  }
}
&, .page__form-check-label{
  color: white;
}
