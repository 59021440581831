.orders__qestionsSelect:invalid
 { color: #fff; }

 .orders__qestionsTitle {
    margin-bottom: 1rem;
    h2{
      text-align: center;
    }
}
.orders__qestionsText {
    margin-bottom: 1rem;
}

select.orders__qestionsSelect {
    max-width: 678px;
    width: 100%;
    background: transparent;
    text-align: center;
    border: 1px solid $orange;
    padding: 1rem;
    font-size: 1rem;
    font-family: 'Oswald', sans-serif;
    color: #fff;
    margin-bottom: 1.8rem;
}
.orders__qestionsSelect option {
    color: #000;
}
& {
    text-align: center;
}


@media screen and (max-width: 767px){
.orders__qestionsTitle h2{
font-size: 2rem;
}
.btn{
  padding: 0.9rem 2.5rem;
}
}
@media screen and (min-width: 992px){
  .orders__qestionsTitle{
    margin-top: 5.125rem;
  }
}
