section.checkout-step .step-title{
  @media screen and (min-width: 992px){
    font-size: 2.25rem;
  }
  @media screen and (max-width: 991px){
    font-size: 1.75rem;
  }
}
#checkout-payment-step{
  #modal.in{
    &::before{
      content: '';
      background-color: #000;
      opacity: 0.5;
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .modal-dialog{
      margin-top: 4rem;
    }
    .close{
      color: $black;
      opacity: 1;
    }
  }
}
.modal-backdrop.fade.in{
  display: none;
}
label{
		margin-top: .25rem;
}
section.checkout-step .custom-radio{
  position: relative;
  top: -0.125rem;
}
section#content{
  // margin-top: 5rem;
}
#js-checkout-summary {
  .card-block{
    padding-top: 0;
    padding-bottom: 0;
  }
  padding: 1.25rem;
}
.js-cart{
  background-color: #d7d7dd42;
  color: black;
  font-weight: 600;
  box-shadow: none;
  border-color: transparent;
  p.cart-products-count{
    color: black;
  }
}
.cart-summary-line.cart-summary-subtotals .label,  .cart-total .label{
  font-weight: 800;
}
.cart-summary-line.cart-summary-subtotals .value, .cart-total .value{
  font-weight: 400;
}
.cart-summary-line{
  margin-top: .5rem;
margin-bottom: .5rem;
}
.js-cart .label, .js-cart label, .js-cart span, .js-cart p{
  color: black;
}
.js-cart a.button_collapse {
	min-height: 2.875rem;
  text-transform: uppercase;
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		height: 2.875rem;
	}
	@supports (-ms-ime-align:auto) {
		height: 2.875rem;
	}
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    background: $gray_presta;
    display: inline-block;
    border-radius: 3rem;
		font-size: 0.875rem;
		padding: 0.75rem 1.5rem;
    cursor: pointer;
		font-family: 'Oswald';
		color:white;
		&:hover, &:focus{
			opacity: 1;
			color:white !important;

		}
		opacity: 1;
		position: relative;
		overflow: hidden;
		-webkit-backface-visibility: hidden;
		 -moz-backface-visibility: hidden;
		 -webkit-transform: translate3d(0, 0, 0);
		 -moz-transform: translate3d(0, 0, 0);
		 &:after{
			 /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1e5799+0,141417+10,141417+90,7db9e8+100&0+0,0.33+10,0.33+90,0+100 */
			 background: -moz-linear-gradient(left, rgba(30,87,153,0) 0%, rgba(20,20,23,0.33) 10%, rgba(20,20,23,0.33) 90%, rgba(125,185,232,0) 100%); /* FF3.6-15 */
			 background: -webkit-linear-gradient(left, rgba(30,87,153,0) 0%,rgba(20,20,23,0.33) 10%,rgba(20,20,23,0.33) 90%,rgba(125,185,232,0) 100%); /* Chrome10-25,Safari5.1-6 */
			 background: linear-gradient(to right, rgba(30,87,153,0) 0%,rgba(20,20,23,0.33) 10%,rgba(20,20,23,0.33) 90%,rgba(125,185,232,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			 filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001e5799', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
		 }

			 &:after {
				 content: '';
				 height: 20rem;
				 position: absolute;
				 transform: translate3d(-10%, -10px, 0) rotate3d(0, 0, 1, 20deg);
				 transition: all 3.25s cubic-bezier(0.19, 1, 0.22, 1);
				 width: 8rem;
				 top:-7rem;
				 left: -9rem;
				 // z-index: -1;
			 }
			 &:hover:after{
				 transform: translate3d(550%, -20px, 0px) rotate3d(0, 0, 1, 45deg);
			 }
			 &:focus:after{
				 transform: translate3d(550%, -20px, 0px) rotate3d(0, 0, 1, 45deg);
			 }
			 &:hover {
				 opacity: 1;
			 }
}
.separator, hr{
  border-color: transparent;
}
.button_wrap{
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 0.75rem;
}
.media-list{
  .media{
    a, img {
      min-width: 3.125rem;
      width: 3.125rem;
      border: 0;
    }
    .product-quantity{
      display: block;
      text-align: right;
      font-weight: 500;

    }
    .product-price{
      font-weight: 400;
    }
  }
  .media{
    border-bottom: 1px solid $gray_lighter_price-steel;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .media:last-of-type{
    border-bottom: 0px solid;
  }
}
#wrapper{
  padding-top: 7rem;
}
& section.checkout-step .delivery-option{
  background-color: transparent !important;
}
.payment-option label{
  position: relative;
  top: -0.625rem;
  left: -0.375rem;
}
.condition-label{
  position: relative;
  left: -0.25rem;
  top: -0.125rem;
}
.payment-options{
  margin-top: 1.5rem;
}
