/* The actual timeline (the vertical ruler) */

.coop_title {
    font-weight: 700;
    font-size: 2.375rem;
    color: $orange;
    line-height: 1.2;
    font-family: 'Oswald';
    margin-bottom: 0.875rem;
}

.coop_timeline {
    position: relative;
    margin: 0 auto;
    margin-bottom: 2.5rem;
}
.coop__qestionsTitle h2{
  text-align: center;
}
/* The actual timeline (the vertical ruler) */
.coop_timeline::after {
    content: '';
    position: absolute;
    width: 2px;
    background-color: $orange;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
}

.coop_doomeDot .coop_content {
    height: 31px;
}
/* Container around content */
.coop_container {
    padding: 0px 2rem;
    position: relative;
    background-color: inherit;
    width: 50%;
}

/* The circles on the timeline */
.coop_container::after {
    content: '';
    position: absolute;
    width: 3rem;
    height: 3rem;
    right: -1.375rem;
    background-color:  $black ;
    border: 1px solid $orange;
    top: -0.125rem;
    border-radius: 50%;
    z-index: 1;
}
.coop_container::before {
    content: '';
    position: absolute;
    width: 2rem;
    height: 2rem;
    right: -0.875rem;
    background-color:  $black ;
    border: 0.625rem solid $orange;
    top: 0.375rem;
    border-radius: 50%;
    z-index: 2;
}
/* Place the container to the left */
.coop_container:nth-child(even) {
    left: 0;
    text-align: right;
}

/* Place the container to the right */
.coop_container:nth-child(odd) {
    left: 50%;
    text-align: left;
}



/* Fix the circle for containers on the right side */
.coop_container:nth-child(odd)::after {
    left: -1.625rem;
}
.coop_container:nth-child(odd)::before {
    left: -1.125rem;
}
/* The actual content */
.coop_content {

    position: relative;
    border-radius: 6px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 767px) {
  /* Place the timelime to the left */
  .coop_timeline::after {
    left: 15px;
    bottom: -4rem;

  }

  /* Full-width containers */
  .coop_container {
    width: 100%;
    padding-left: 3rem;
    padding-right: 0.5rem;
    margin-bottom: 2rem;
  }

  .coop_container:nth-child(even){
    text-align: left;
  }

  /* Make sure that all arrows are pointing leftwards */
  .coop_container:nth-child(even)::before, .coop_container:nth-child(odd)::before {
    left: -0.25rem;
  }

  /* Make sure all circles are at the same spot */
  .coop_container:nth-child(even)::after, .coop_container:nth-child(odd)::after {
    left: -0.75rem;
  }

  /* Make all right containers behave like the left ones */
  .coop_container:nth-child(odd) {
    left: 0%;
  }
  .coop_container:nth-child(even):last-of-type::after, .coop_container:nth-child(odd):last-of-type::after {
    left: -0.75rem;
    top: 3.5rem;

  }
  .coop_container:nth-child(even):last-of-type::before, .coop_container:nth-child(odd):last-of-type::before {
    left: -0.25rem;
    top: 4rem;
  }
}

@media screen and (max-width: 767px){
.coop__qestionsTitle h2{
    font-size: 2rem;
}
.coop_title {

    font-size: 2rem;
}

}
