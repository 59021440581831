&{
	overflow: hidden;
	pointer-events: none;
	display: none;
}
.-is-tablet-mobile &{
	display: none;
	pointer-events: none;
	overflow: hidden;
}
body:not(.is-tablet-mobile) &{
	display: flex;
	// overflow: hidden;
	pointer-events: none;

}
body.deleted &{
	display: none;
	pointer-events: none;
	overflow: hidden;
}
/* Preloader */
body:not(.is-tablet-mobile) & .ctn-preloader {
	position: absolute;
	//left: 0;
  top: 0;
  z-index: 5001;
	height: 100vh;
	max-width: 1920px;
	max-height: 1342px;
	width: 100%;
	overflow: hidden;

	// @supports (overflow:-webkit-marquee) and (justify-content:inherit) {
	// 	height: 100vh;
	// 	max-width: 1920px;
	// 	max-height: 1342px;
	// 	width: 100%;
	// }
	// @supports (-ms-ime-align:auto) {
	// 	height: 100vh;
	// 	max-width: 1920px;
	// 	max-height: 1342px;
	// 	width: 100%;
	// }
	// @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	// 	height: 100vh;
	// 	max-width: 1920px;
	// 	max-height: 1342px;
	// 	width: 100%;
	// }
	// @supports (-ms-ime-align:auto) {
	// 	height: 100vh;
	// 	max-width: 1920px;
	// 	max-height: 1342px;
	// 	width: 100%;
	// }
}
body:not(.is-tablet-mobile) & #ctn-preloader:not(.image)::before{
		content: '';
		position: absolute;
		z-index: 5000;
		// background: url('img/preloader.jpg');
		background-color: $black;
		height: 100vh;
	  width: 1920px;
		// width: 100%;
		max-height: 1342px;
		// width: 100%;
		background-image: url('img/preloader.jpg');
		background-position: top center;
		// margin: 0 auto;
		transform: translate(-50%);
		left: 50%;

}
canvas{
	height: 100vh;
	width: 1920px;
	max-height: 1342px;
	// width: 100%;
	// margin: 0 auto;
	// display: block;
	overflow: hidden;
	// left: 50%;
  // transform: translate(-50%);
	position: absolute;
}
// @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
// 		body:not(.is-tablet-mobile) & #ctn-preloader::before{
// 			background: transparent none !important;
// 			display: none;
// 		}
// 		body:not(.is-tablet-mobile) & #ctn-preloader:not(.image)::before{
// 			background: transparent none !important;
// 			display: none;
// 		}
// }
// @supports (overflow:-webkit-marquee) and (justify-content:inherit) {
// 	body:not(.is-tablet-mobile) & #ctn-preloader::before{
// 		background: transparent none !important;
// 		display: none;
// 	}
// 	#ctn-preloader:not(.image)::before{
// 		background: transparent none !important;
// 		display: none;
// 	}
// }
// @supports (-ms-ime-align:auto) {
// 	body:not(.is-tablet-mobile) & #ctn-preloader::before{
// 		background: transparent none !important;
// 		display: none;
// 	}
// 	body:not(.is-tablet-mobile) & #ctn-preloader:not(.image)::before{
// 		background: transparent none !important;
// 		display: none;
// 	}
// }
// @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
// 	body:not(.is-tablet-mobile) & .ctn-preloader .loader-section {
// 	  background-color: $black;
// 	  height: 100%;
// 		position: absolute;
// 	  top: 0;
// 	  width: calc(50% + 1px);
// 	}
// 	body:not(.is-tablet-mobile) & .ctn-preloader .loader-section.section-left {
// 	  left: 0;
// 	}
// 	body:not(.is-tablet-mobile) & .ctn-preloader .loader-section.section-right {
// 	  right: 0;
// 	}
// }
// @supports (-ms-ime-align:auto) {
// 	body:not(.is-tablet-mobile) & .ctn-preloader .loader-section {
// 	  background-color: $black;
// 	  height: 100%;
// 		position: absolute;
// 	  top: 0;
// 	  width: calc(50% + 1px);
// 	}
// 	body:not(.is-tablet-mobile) & .ctn-preloader .loader-section.section-left {
// 	  left: 0;
// 	}
// 	body:not(.is-tablet-mobile) & .ctn-preloader .loader-section.section-right {
// 	  right: 0;
// 	}
// }
// @supports (overflow:-webkit-marquee) and (justify-content:inherit) {
// 	body:not(.is-tablet-mobile) & .ctn-preloader .loader-section {
// 	  background-color: $black;
// 	  height: 100%;
// 		position: absolute;
// 	  top: 0;
// 	  width: calc(50% + 1px);
// 	}
// 	body:not(.is-tablet-mobile) & .ctn-preloader .loader-section.section-left {
// 	  left: 0;
// 	}
// 	body:not(.is-tablet-mobile) & .ctn-preloader .loader-section.section-right {
// 	  right: 0;
// 	}
// }

//
// /* Efecto de fade en la animación de cargando */
body:not(.is-tablet-mobile) &.loaded .animation-preloader {
  // opacity: 0;
  transition: 0.3s ease-out;
}
//
// /* Efecto de cortina */
// body:not(.is-tablet-mobile) &.loaded .loader-section.section-left {
//   transform: translateX(-101%);
//   transition: 0.7s 0.3s all cubic-bezier(0.1, 0.1, 0.1, 1.000);
// }
//
// body:not(.is-tablet-mobile) &.loaded .loader-section.section-right {
//   transform: translateX(101%);
//   transition: 0.7s 0.3s all cubic-bezier(0.1, 0.1, 0.1, 1.000);
// }
// body:not(.is-tablet-mobile) & .animation-preloader svg {
// 	width: 90%;
//   max-width: 90rem;
// 	z-index: 1;
// 	height: auto;
// 	position: relative;
// 	opacity: 0;
// 	transition: fill 0.5s ease;
// 	z-index: 5003;
// }
// body:not(.is-tablet-mobile) & .animation-preloader svg {
// 	opacity: 1;
// }
body:not(.is-tablet-mobile) &.loaded{
	pointer-events: none;
}

body:not(.is-tablet-mobile) & .animation-preloader{
	height: 100vh;
	max-width: 1920px;
	max-height: 1342px;
	// width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
body:not(.is-tablet-mobile) &.animation_end{
	display: none;
}
body:not(.is-tablet-mobile) & .preloader-bg {
	overflow: hidden;

	position: absolute;
	height: 100vh;
	width: 1920px;
	max-height: 1342px;
		z-index: 5002;
		transform: translate(-50%);
		left: 50%;
		// @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		// 	display: none;
		// }
		// @supports (-ms-ime-align:auto) {
		// 	display: none;
		// }
		// @supports (overflow:-webkit-marquee) and (justify-content:inherit) {
		// 	display: none;
		// }
		// img{
		// 	height: 100vh;
		// 	max-width: 1920px;
		// 	max-height: 1342px;
		// 	width: 100%;
		// 	z-index: 5002;
		// 	position: absolute;
			// @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			// 	display: none;
			// }
			// @supports (-ms-ime-align:auto) {
			// 	display: none;
			// }
			// @supports (overflow:-webkit-marquee) and (justify-content:inherit) {
			// 	display: none;
			// }
		// }
}
body:not(.is-tablet-mobile) & canvas {
	// @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	// 	display: none;
	// }
	// @supports (-ms-ime-align:auto) {
	// 	display: none;
	// }
	// @supports (overflow:-webkit-marquee) and (justify-content:inherit) {
	// 	display: none;
	//
	// }
	// overflow: hidden;
	// position: absolute;
	// backface-visibility: hidden;
	// -webkit-backface-visibility: hidden;
	// -moz-backface-visibility: hidden;
	// -ms-backface-visibility: hidden;
}
body:not(.is-tablet-mobile) & .animation-preloader{

// svg * {
// 	stroke: white;
//   stroke-width: 8;
//   fill-opacity: 1;
// 	fill: $orange;
// 	stroke-dasharray: 1350;
// 	stroke-dashoffset: 1940;
// 	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
// 		fill: $orange;
// 		fill-opacity: 1;
// 		stroke-width: 0;
// 	}
// }
// svg{
// 	-webkit-filter: url("#blur");
// 	filter: url("#blur");
// 	transform: scale(1) translate3d(0,0,0);
// 	transition: all .3s cubic-bezier(.1,1,.7,1);
// }
// svg.animate{
// 	transform: scale(1) translate3d(0,0,0);
// }

// .animation{transition: all .3s cubic-bezier(.1,1,.7,1);}


// svg #bp_svg * {
//
//   -webkit-animation-name : dash;
//   -moz-animation-name : dash;
//   -o-animation-name : dash;
//   animation-name : dash;
//
//   -webkit-animation-duration: 2s;
//   -moz-animation-duration: 2s;
//   -o-animation-duration: 2s;
//   animation-duration: 2s;
//
//   -webkit-animation-timing-function : linear;
//   -moz-animation-timing-function : linear;
//   -o-animation-timing-function : linear;
//   animation-timing-function : linear;
//
//   -webkit-animation-fill-mode : forwards;
//   -moz-animation-fill-mode : forwards;
//   -o-animation-fill-mode : forwards;
//   animation-fill-mode : forwards;
//
//   -webkit-animation-delay : 0s;
//   -moz-animation-delay : 0s;
//   -o-animation-delay : 0s;
//   animation-delay : 0s;
// }
// svg.-fade-out {
//
// 	-webkit-animation-name : fadeout;
// 	-moz-animation-name : fadeout;
// 	-o-animation-name : fadeout;
// 	animation-name : fadeout;
//
// 	-webkit-animation-duration: 1.5s;
// 	-moz-animation-duration: 1.5s;
// 	-o-animation-duration: 1.5s;
// 	animation-duration: 1.5s;
//
// 	-webkit-animation-timing-function : cubic-bezier(.1,1,.6,1);
// 	-moz-animation-timing-function : cubic-bezier(.1,1,.6,1);
// 	-o-animation-timing-function : cubic-bezier(.1,1,.6,1);
// 	animation-timing-function : cubic-bezier(.1,1,.6,1);
//
// 	-webkit-animation-fill-mode : forwards;
// 	-moz-animation-fill-mode : forwards;
// 	-o-animation-fill-mode : forwards;
// 	animation-fill-mode : forwards;
//
// 	-webkit-animation-delay : 0s;
// 	-moz-animation-delay : 0s;
// 	-o-animation-delay : 0s;
// 	animation-delay : 0s;
// }

}
@media screen and (max-width: 991px){
	&{
		display: none !important;
	}
}
