.card .cart-container, .card{
  box-shadow: none;
  padding: 0;
  border: 0px solid;
  .separator{
    border-top: 0px solid;
  }
}
#block-reassurance li{
  border-bottom: 0px solid;
}
.cart-item{
  border-bottom: 1px solid $gray_lighter_price-steel;
}
.cart-item:last-of-type{
  border-bottom: 0px solid;
}
.cart-summary{
  background-color: $black;
  color: white;
  label, .label, span{
    color: white;
  }
  .label{
    font-weight: 700;
  }
}
.cart-content .cart-content-btn {
    display: flex;
    justify-content: space-between;
}
.product-line-info{
  a.label{
    color: $black;
    &:hover, &:focus{
      color: $black;
    }
    font-family: 'Oswald';
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.125rem;
  }
}
.text-sm-center{
  display: flex;
  justify-content: center;
}
.label.btn-sec{
  opacity: 1;
  position: relative;
  overflow: hidden;
  width: 15rem;
  -webkit-backface-visibility: hidden;
   -moz-backface-visibility: hidden;
   -webkit-transform: translate3d(0, 0, 0);
   -moz-transform: translate3d(0, 0, 0);
   background-color: $gray-secondary;
   color: $black;
   min-height: 2.875rem;
   display: flex;
   justify-content: center;
   align-items: center;
   margin: auto;
   &:after {
 		content: '';
 		height: 20rem;
 		position: absolute;
 		transform: translate3d(-10%, -10px, 0) rotate3d(0, 0, 1, 20deg);
 		transition: all 3.25s cubic-bezier(0.19, 1, 0.22, 1);
 		width: 8rem;
 		top:-7rem;
 		left: -9rem;
 		// z-index: -1;
 	}
 	&:hover:after{
 		transform: translate3d(550%, -20px, 0px) rotate3d(0, 0, 1, 45deg);
 	}
 	&:focus:after{
 		transform: translate3d(550%, -20px, 0px) rotate3d(0, 0, 1, 45deg);
 	}
 	&:after {
    background: -moz-linear-gradient(left, rgba($black,0) 0%, rgba($black,0.5) 10%, rgba($black,0.5) 90%, rgba($black,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba($black,0) 0%,rgba($black,0.5) 10%,rgba($black,0.5) 90%,rgba($black,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba($black,0) 0%,rgba($black,0.5) 10%,rgba($black,0.5) 90%,rgba($black,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001e5799', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
 	}
  @media screen and (min-width: 576px){
    margin-left: 2rem;
  }
  @media screen and (max-width: 992px){
    margin-bottom: 2rem;
  }
}
.product-line-info{
  .label{
    font-weight: 900;
    font-size: 1rem;
    line-height: 1.125;
  }
}
.current-price{
  font-size: 1.125rem;
}
.product-line-grid-left{
  padding-right: 0;
  padding-left: 0;
  .product-image img{
    width: 100%;
  }
}
p.cart-products-count{
  font-size: 1.5rem;
  font-weight: 300;
  color: white;
  margin-bottom: 1.5rem;
}
.cart-summary-line{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.cart-detailed-actions{
  margin-top: -1.25rem;
}
.product-line-grid{
  @media screen and (min-width: 992px){
    display: flex;
    align-items: center;
  }
}
.product-price.product-line-info {
  margin-bottom: 0;
  margin-top: 0.25rem;
}
.cart-summary{
  background-color: #d7d7dd42;
  color: black;
  p.cart-products-count{
    color: black;
  }
}
.cart-summary .label, .cart-summary label, .cart-summary span{
  color: black;
}
