.h1{
  color: $black;
  font-family: 'Oswald';
  font-weight: bold;
  font-size: 1.75rem;
  text-transform: uppercase;
}
&{
  border-radius: 0;
}
.modal-content{
  border-radius: 0;
}
.border-top{
  border-top: 0px solid;
}
.product-gallery__wrapper{
  .gallery_small{

    .big-image{

      img{

        @media screen and (max-width: 1400px){
          width: 5.5em;
          height: 4em;

        }
      }
    }
  }
}
#product-availability{
  margin-top: 2.5rem;
  display: flex;
  justify-content: flex-end;
  font-size: 1rem;
  font-family: 'Oswald';
  text-transform: uppercase;
  i{
    color: $orange;
    position: relative;
    top: 0.25rem;
    margin-right: 0.25rem;
    font-size: 1.125rem;
  }
}
.more{
  margin-left: 0.5rem;
  .btn{
    min-width: 12rem;
    align-items: center;
    display: flex;
    justify-content: center;
    color: $black;
  }
}
@media screen and (max-width: 991px){
  .product-quantity{
    flex-wrap: wrap;
  }
  .more{
    margin-left: 0;
  }
}
@media screen and (max-width: 767px){
  .more{
    margin-left: 0.5rem;
  }
  .modal-dialog{
    width: calc(100%);
  }
}
.product-price{
  font-size: 1.375rem;
}
.modal-footer{
  border-top: 0px solid;
}
.product-add-to-cart{
  margin-top: 1.5rem;
}
