// ################ GLOBAL SETTINGS ###########
body{
  font-family: $font_default;
  color: $color_default;
}


@include selection($selection_bgn, $selection_color);



//hover options not for touch devices - detecting by js: touchscreenDetect()
body.noTouchscreen {
	button, a {
		transition: color .3s ease,
		background-color .3s ease,
		border-color .3s ease,
		opacity .3s ease;
	}
	button:hover, a:hover { opacity: 0.7; }
}
html{
font-size:100%;
}
@media screen and (max-width: 991px) {
	html{
		font-size:100%;
	}
}
@media screen and (max-width: 1439px) and (min-width: 992px) {
html{
  font-size:80%;
}
}
