.page__pageText{
  ul li, ol li{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  ul, ol{
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

#wrapper.original_content_section{
  padding-top: 4.75rem;
}
.page__added #wrapper.original_content_section {
  padding-top: 0;
  background-color: $black;
  @media screen and (max-width: 991px){
    padding-top: 3rem;
  }
}
#index #wrapper.original_content_section{
  padding-top: 1.75rem;
  background-color: $black;
}

.about__textLarge{
  @media screen and (max-width: 767px){
    line-height: 1.375 !important;
  }
}
.text__heading, .page__about .about__column--left .about__textTop--left{
  @media screen and (max-width: 500px){
    line-height: 1.25 !important;
  }
}
.page__about ,
.about__middle ,
.page__about--bottom {
  .page__subPageTitle.main{
    margin-bottom: 0;
    h2{
      font-size: 6.25rem;
      top: -3rem;
      @media screen and (max-width: 991px){
        margin-top: 3rem;
        top: -1.5rem;

      }
      position: relative;
    }
  }
  .about__textTop--left, .text__heading {
    line-height: 1.25;
  }
  .page__subPageText.top{
    // top: -3rem;
    position: relative;
  }
  .page__subPageTitle.small h2{
    font-size: 3rem;
  }
  .text__brand.text__heading.top{
    @media screen and (min-width: 992px){
      top: -3rem;
    }
    position: relative;
  }
}
@media screen and (min-width: 992px){
  span.break{
    display: block;
  }
}
@media screen and (max-width: 991px){
  span.break{
    display: inline-block;
  }
}
#footer{
  // background-color: $orange;
}
#wrapper{
  // padding-top: 4.75rem;
}
#index #wrapper{
  // padding-top: 1.75rem;
}
#mmenu_mobile{
  &:not(.mm-menu){
    display: none;
  }
}


// produkt pojedynczy

.product-accessories{
  background-color: white;
}


// 0.875
#wrapper{


  .breadcrumb{
    font-size: 14px;
  }

  .breadcrumb li::after {
      content: "|";
      color: #7A7A7A;
      margin: 0.3125rem;
  }

  .breadcrumb li.current, .breadcrumb li:last-child a{
    color: $orange;
    font-weight: bold;
  }
}
#checkout {
  .search-mobile {
    display: none; /* Not ideal solution by allows to reuse same hooks/templates */
  }
}
#search_widget{
  input[type=text]{
    border: 0px solid;
  }
}
#products .page-not-found, #pagenotfound .page-not-found{
  box-shadow: none;
}

// lightgallery
///////////////////////

// usun zaokraglenie ramki
.lg-outer .lg-thumb-item{
  border-radius: 0px !important;
}
.lg-outer .lg-thumb-item{
  border-color: white !important;
}

.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover{
  border-color: $orange !important;
}

// polprzezroczyste tlo
.lg-backdrop{
  background-color: rgba(black, 0.8) !important;
}

//  niech miniaturki nie przykrywaja zdjecia, skopiowano juz istniejace transition

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap, .lg-outer .lg-img-wrap{
  transition: left .3s cubic-bezier(0,0,.25,1) 0s,top .3s cubic-bezier(0,0,.25,1) 0s, padding 0.2s !important;
}

.lg-thumb-open .lg-img-wrap {
    padding-bottom: 100px !important;
}

// lista produktow waluta pomniejszona na modale
.product-miniature, .modal-content, .modal-dialog  {

.price .currency{
    font-size: 0.9rem;
    text-transform: uppercase;
    position: relative;
    top: -0.3rem;
    padding-left: 0.3rem;
}
.product-price .currency{
    text-transform: uppercase;
    position: relative;
    font-size: 0.9rem;
    padding-left: 0.2rem;
    top: -0.3rem;
}
.regular-price .currency{
    text-transform: uppercase;
    position: relative;
    font-size: 0.7rem;
    padding-left: 0.1rem;
    top: -0.2rem;
}
.current-price .currency{
  font-size: 0.9rem;
  text-transform: uppercase;
  position: relative;
  top: -0.3rem;
  padding-left: 0.3rem;
}

}
.product-line-grid{
  .current-price .currency{
    text-transform: uppercase;
    position: relative;
    font-size: 0.75rem;
    padding-left: 0rem;
    top: -0.25rem;
  }
}



// stylowanie ikon Font Awesome
.btn-touchspin i{
  color: $black;
  font-weight: 900 !important;
  left: 0.35rem !important;
  top: 0.125rem !important;
  @media screen and (min-width: 992px) and (max-width: 1400px){
    left: 0.5rem !important;
  }
  font-size:0.875rem !important;
}

// dodany padding bo przycinalo strone
#checkout{
  #wrapper{
    padding-top: 3rem;
  }
}
//fix dla modal quickview
.lg-backdrop{
  z-index: 9999 !important;
}
.lg-outer{
  z-index: 10000 !important;

}
body#checkout a:hover {
    color: inherit;
}

// footer w zamowieniu

body#checkout #footer{
  padding: 0px;
}
// // fix mmenu  scroll top
html { height: auto; }
// fix mmenu checkout page

.header-top{
  @media screen and (max-width: 991px){
    display: none;
  }
}
.add{
  svg{
    fill: white;
    height: 1.75rem;
    width: 2rem;
  }
}
.page__about--bottom{
  padding-bottom: 0;
}
.-is-upper{
  text-transform: capitalize;
}
@media screen and (max-width: 767px){
  .form-control-label.-empty{
    display: none;
  }
  #payment-confirmation .ps-shown-by-js{
      display: flex;
      justify-content: flex-end;
  }
}
#header .dropdown-item{
  font-weight: bold;
}
.js-terms a{
  text-decoration: underline;
}

#product.product-produkt-specjalny #wrapper{
  padding-bottom: 1rem;
}



///////////////////////////////////
// wylaczone oznaczenie produktow
.product-flags{
  display: none;
}


//////////////////////////////////////
// formularz kontaktowy

.-sending{
  pointer-events: none;
}

////////////////////////////////////
// strona zamowienie indywidualne
.orders__qestionsForm{

 input, textarea {
  width: 100%;
  background: transparent;
  border: 1px solid #fff;
  // text-align: center;
  padding: 1rem;
  color: #fff;
  font-family: 'Oswald', sans-serif;
  font-size: 1rem;
  margin-bottom: 1rem;
  @media screen and (max-width: 991px){
    padding: 0.625rem;
  }
}

.page__form-check-label{
  color: white;
  margin-left: 0.2rem;
  margin-top: 0.2rem;
  text-align: left;
      line-height: 1.5;
}

.product__formChboxWrap{
  display: flex;
}

#product__form{
    width: 600px;
    margin: 0px auto;
    margin-top: 3rem;
    max-width: 100%;
}

}

.orders__qestionsTitle{
      margin-bottom: 2rem;
}

.special__app{
  margin-top: 2rem;
  margin-bottom: 2rem;
}


///////////////////////////////////////////////
// poprawka o nas

@media (min-width: 992px){
  .text__brand.text__heading.top{
    margin-top: 6rem;
  }
}

///////////////////////////////////////////////////

.product-description p{
  margin-bottom: 1rem;
}


/////////////////////////////

// wyniki wyszukiwania

#products .products{
  justify-content: flex-start;
}

//////////////////////////////

// strona regulamin

.page-content, .js-modal-content{

  p, ul, li{
    margin-bottom: 1rem !important;
      font-weight: 400;
  color: black;
  }
}

//////////////////////////////

// koszyk

.condition-label a{
  text-decoration: underline;
}

// modal styling

#cart .modal-content, #order-confirmation .modal-content, #order-detail .modal-content {
  position: relative;
  top: 6rem;
}
#cart .modal-backdrop, #order-confirmation .modal-backdrop, #order-detail .modal-backdrop{
  display: none;
}
.-no-image{
  filter: blur(12px);
}

// koszyk kustomizacja

body#cart{
  .product-customization-line, .product-line-info {
      margin-top: 0.5rem;
      .label{
        font-weight: 400;
      }
  }
}

// koszyk platnosc

body#checkout section.checkout-step .payment-options label{
  display: flex;
  flex-wrap: wrap;
  span{
    margin-right: 1rem;
  }
}

body#checkout .additional-information{
    margin-top: -1.5rem;
    margin-bottom: 1.5rem;
}


body#checkout section.checkout-step #conditions-to-approve{
  padding-top: 0px;
    margin-bottom: 1rem;
}


///
// guzkki
.btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus{
  outline: none;
}

////////////////////////////


.app__hidden{
  opacity: 0;
  pointer-events: none;
  width: 0;
}

.mm-menu:not(.mm-menu_opened){
  pointer-events: none;
}

// lista 

@media screen and (max-width: 767px){
  .pagination .page-list{
    justify-content: center !important;
  }
}

// poj produkt zobacz także

.single-produkt-related{
    padding-top: 2rem;
    padding-bottom: 2rem;

    .swiper-button-disabled{
      opacity: 0;
      pointer-events: none;
    }

}

// zamowienie

@media (max-width: 500px){
  .nav-inline .nav-item+.nav-item{
    margin-left: 0px;
    display: block;
  }
}


// produkcja

.production_slider{
  padding-top: 2rem;
}

// strony cms
#cms #main #content{
        max-width: 800px;
      .text__paragraph, p{
        font-size: 0.9rem;
      }

      h2{
        font-size: 2rem;
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;
      }
}