#index &{
  padding: 2rem 0;
}
.head__desc{
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 1rem;
  margin-top: -0.5rem;
  @media screen and (max-width: 767px){
    font-size: 1rem;
    margin-top: 1.875rem;
  }

}
.normal__desc{
  font-size: 1rem;
  font-weight: 300;
  max-width: 56.25rem;
  margin-bottom: 2rem;
}
.swiper-container-free-mode{
  @media screen and (max-width: 991px){
    padding-bottom: 2rem;
  }
  @media screen and (max-width: 767px){
    padding-bottom: 4.5rem;
  }
}
.products{
  .product-thumbnail {
    display: block;
    width: 25.75em;
    height: 17.75em;
    img{
      width: 25.75em;
      height: 17.75em;
    }
  }
  .thumbnail-container {
    position: relative;
    margin-bottom: 1.563em;
    height: 23.625em;
    width: 25.75em;
    @media (max-width: 767px){
      font-size: 0.6rem;
      width: 25.75em;
      position: relative;
      .product-description {
        bottom: -1.25em;
        max-width: 100%;
      }
      .product-thumbnail{
        width: 25.75em;
        height: 25.75em;
        img{
          width: 25.75em;
          height: 17.5em;
        }
      }
      margin-bottom: 0;

    }

    box-shadow: none;

    background: transparent;
    .product-description {
      transition: all 0.5 ease;
      .regular-price, .price{
        transition: all 0.5 ease;

      }
    }

  }

  .product-description {
    position: absolute;
    z-index: 1;
    background: $black;
    width: 25.75em ;
    // height: 4.375em;
  }
}
.products .swiper-slide, & .swiper-container.swiper-container-free-mode .swiper-slide{
  width: 26em !important;
  margin-left: 0.375em;
  margin-right: 0.375em;
}
.products .swiper-slide, & .swiper-container.swiper-container-free-mode .swiper-slide{
  @media (max-width: 767px){
    width: 20.6875em !important;
    margin-left: 0.375em;
    margin-right: 0.375em;
  }
}
.add-product{
 position: absolute;
 right: 0;
 bottom: 0;
 padding: 1rem 4rem;
 color: white;
 font-size: 0.875rem;
  &:focus, &:hover{
    color: white;
  }
}
.page__container{
  position: relative;
}
&{
  background-color: white;
}
h1{
  margin-bottom: 1.25rem;
  text-transform: uppercase;
}
