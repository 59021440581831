.block-category{
  box-shadow: none;
  border: 0px solid;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  background: transparent;
  min-height: auto;
  @media screen and (max-width: 991px){
    padding-left: 0;
  }
  margin-bottom: 1rem;
}
.products-sort-order .select-title{
  box-shadow: none;
}
& #main{
  padding-top: 0;
  .products-selection{
    margin-bottom: 0;
  }
}
.block-categories li.active:not(ul) > a{
    color: $orange;
}
.category-sub-menu{
  margin-top: 2rem;
}
.category-top-menu{
  // margin-top: -1rem;
}
li[data-depth="1"]{
  margin-left: 1rem;
  font-weight: 500;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
li[data-depth="0"]{
  // padding-top: 0.25rem;
  a{
    margin-top: 0.5rem;
    padding-bottom: 0.3875rem;
  }
}
.block-categories{
  box-shadow: none;
  min-width: 15rem;
}
.left-column{
  // display: inline-flex;
  // width: 10rem;
}

#left-column{
  @media screen and (max-width: 991px){
    display: none;
  }
}
#content-wrapper{
  @media screen and (max-width: 991px){
    width: 100%;
  }
}
.btn-secondary{
  align-items: center;
  display: inline-flex;
  justify-content: center;
  text-transform: uppercase !important;
}
// #products{
//   display: none;
// }
.card-block {
  padding: 0;
}
#js-active-search-filters{
  display: none;
}

#products .product-miniature{
  margin-top: -0.8125rem;
  margin-bottom: -0.8125rem;
  margin-left: 0.375em;
margin-right: 0.375em;
}
#products .btn-secondary{
  color: $black;
}
h1{
  margin-left: 0;
  @media screen and (max-width: 991px){
    margin-left: 15px;
  }
}

#left-column{
  width: 16rem;
}
#content-wrapper{
  @media screen and (min-width: 991px){
    margin-left: 2rem;
  }
  width: 100%;
}
.columns__flex{
  display: flex;
}

#main .block-category{
  display: flex;
  justify-content: space-between;
}
.justify__content{
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 991px){
    display: block;
  }
}
#products .products{
  justify-content: flex-start;
  @media (max-width: 550px){
    justify-content: center;
  }
}
