& {
    background-image: url(img/opinions/bg.jpg);
    background-size: cover;
    padding: 3.4rem 0rem;
    overflow: hidden;

}
.opinions__content {
    display: flex;
}
.opinions__opinionTitle h2{
  text-transform: none;
}
.opinions__brandName {
    font-family: 'Oswald';
    font-weight: 600;
    font-size: 6rem;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 2rem;
    @media (max-width: 500px){
      font-size: 2.5rem;
    }
}
.opinions__left {
    max-width: 28rem;
    width: 40%;
    @media (min-width: 992px){
      min-width: 28rem;

    }
}
.opinions__right {
    width: 60%;
}
.opinions__sliderWrap {
    position: relative;
}

.intro__slidePager span {
    border: 1px solid #fff;
    background: #fff;
    border-radius: 2px;
    display: inline-block;
    width: 2px;
    height: 40px;
    margin-left: 1.5rem;
    transform: rotate(-25deg);
    cursor: pointer;
}
.intro__slidePager span.active {
    border-color: $orange;
    background: $orange;
}
.intro__slidePager {
    width: 100%;
    text-align: right;
}
.opinions__sliderButons {
    display: inline-block;
}
.opinions__sliderLink {
    display: inline-block;
    @media screen and (min-width: 551px){
      margin-right: 2rem;
    }
}
.opinions__sliderControls {
    display: flex;

}
.opinions__slider {
    margin-bottom: 2rem;

}
#opinions__slider:not(.slick-initialized) .opinions__opinions:not(:first-of-type){
  display: none;
}
 .newsleater_btnWrap {
    text-align: center;
}
.opinions__wrap{
  // margin: 0rem 7.2rem 0rem 7.2rem;

}
.opinions__sliderLink .btn-long {
    padding: 0.8rem 4rem;
}
.btn-slider.btn-icon {
    width: 2.9rem;
    height: 2.9rem;
    padding: 0.7rem 0.7rem;
    border-radius: 100%;
    position: relative;
}
.opinions__arrow svg {
    width: 1rem;
    height: 1.25rem;
    fill: $black ;
    transition: 0.3s;
    #pusty{
      opacity: 1;
      transition: 0.3s;
    }
    #pelny{
      opacity: 0;
      transition: 0.3s;
    }
}
.btn-icon .opinions__arrow {
    width: 0px;
    height: 0px;
    position: absolute;
    left: 1.125rem;
    top: 0.75rem;

}
.opinions__arrow.arrow_right {
  left: 1.125rem;
  top: 0.875rem;

}
.opinions__arrow svg .fil0, .opinions__arrow svg {
    fill: #151517;
}
.opinions__arrow.arrow_Left svg {
	-webkit-transform: rotate( -180deg );
    -ms-transform: rotate( -180deg );
    transform: rotate( -180deg );
    left: -6px;
    top: 1px;
    position: relative;
}
.opinions__sliderButons .btn-slider {
    opacity: 0.7;
    transition: 0.3s;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
}
body.noTouchscreen &{
  .opinions__sliderButons .btn-slider:hover {
      opacity: 1;
      transition: 0.3s;
  }
  .opinions__sliderButons .btn-slider:hover .opinions__arrow svg .fil0, .opinions__sliderButons .btn-slider:hover .opinions__arrow svg {
  	transition: 0.2s;
  	fill: $orange;
    transition: 0.3s;
    #pusty{
      opacity: 0;
      transition: 0.3s;
    }
    #pelny{
      opacity: 1;
      transition: 0.3s;
    }
  }
}
.opinions__title {
  font-weight: 300;
  font-size: 3.25rem;
  font-family: 'Oswald';
}
.opinions__opinionTitle{
  margin-bottom: 1.25rem;
}
.opinions__opinion {
    /*min-height: 15rem;*/
}
.slick-prev {
    left: 45%;
}
.slick-next {
    right: 45%;
}
.slick-prev, .slick-next{
    top: 100%;
}
.slick-dots li {
    position: relative;
    cursor: pointer;
    border: 1px solid #fff;
    background: #fff;
    border-radius: 2px;
    display: inline-block;
    width: 2px;
    height: 40px;
    margin-left: 1.5rem;
    transform: rotate(-25deg);
    cursor: pointer;
}
.slick-dots li.slick-active{
    border-color: $orange;
    background: $orange;
}
.slick-dots{
    right: 0.5rem;
    width: auto;
}
.slick-dots li button:before{
    display: none;
}
.slick-dots li button {
    transform: rotate(23deg);
    height: 2.4rem;
    top: 0rem;
    position: relative;
    left: -0.6rem;
}
.slick-dots {
    position: absolute;
}
.slick-dots {
    position: absolute;
    bottom: -5rem;
}

@media screen and (max-width: 1400px){

}
@media screen and (max-width: 991px){
.opinions__wrap {
  margin: auto;
}
.opinions__content {
    display: block;}
.opinions__left,.opinions__right {
    width: 100%;
    max-width: initial;
}
}
@media screen and (max-width: 767px){
.opinions__brandName{
    font-size: 3rem;
}
.opinions__title {
  font-size: 2rem;
}


.opinions__opinionTitle  h2{
    font-size: 1.5rem;
}
.opinions__sliderControls {
    display: block;
}
.opinions__sliderLink {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

ul.slick-dots {
    margin-right: 15px;
}

}
.text__paragraph{
  color: white;
}
@media screen and (max-width: 550px){
  .opinions__sliderButons {
      display: block;
  }
.opinions__sliderControls {
    display: block;
    text-align: center;
}
.opinions__slider ul.slick-dots li{
    display: none;
}
.opinions__sliderButons .btn{
        padding: 1rem 1.2rem;
}
}
@media screen and (max-width: 320px){

}
@media screen and (max-width: 500px){
  .opinions__brandName {
     font-size: 2.5rem;
 }
}
