.tabs{
  box-shadow: none;
}
.current-price span[itemprop=price]{
  font-size: 2.25rem;
  .currency{
    font-variant:small-caps;
    position: relative;
    top: -0.675rem;
    font-size: 1.5rem
  }
}
.ask_popup_open{
  color: $black;
  background-color: $gray-secondary !important;
  width: 15rem;
  max-width: 100%;
}
.add-to-cart{
  width: 15rem;
  max-width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

}
.product-quantity{
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
  align-items: center;
  .product-prices{
    margin-left: 3rem;
  }
  .product-prices div{
    margin-bottom: 0;
  }
  @media screen and (max-width: 600px){
    // flex-direction: column;
    .product-prices{
        margin-left: 0;
    }
  }
}
.product-variants-item{
  .form-control{
    display: inline-flex;
  }
  @media screen and (max-width: 600px){
    justify-content: center;
    display: flex;
    align-items: center;
  }
}
.product-prices{
  margin-top: 0;
}
.add{
  display: flex;
  justify-content: flex-start;
  margin-top: 1.625rem;
  margin-bottom: 1rem;
  @media screen and (max-width: 600px){
    // justify-content: center;

  }
}
.product-actions .control-label {
    width: auto;
    margin-bottom: 0;
    margin-right: 0.75rem;
    display: inline-block;
    /* width: 100%; */

  }
  .product-actions{
    @media screen and (max-width: 991px){
      margin-bottom: 2rem;
    }
  }
.product-flags {
top: auto;
}
.product-information{
[itemprop=description]{
    margin-bottom: 2.5rem;
}
}
.product-information{
  margin-top: 3rem;

[itemprop=description], .product-description{
  p, span, p span{
    color: $black !important;
    font-family: 'Roboto' !important;
    font-size: 1rem !important;
    font-weight: 300 !important;
  }
  ul{
    li, li p, li span, li span span{
      color: $black !important;

      font-family: 'Roboto' !important;
      font-weight: 300 !important;

    }
    li{
      display: flex;
      margin-top: 1.125rem;
      margin-bottom: 1.125rem;
    }
    li:before{
      content: '';
      font-family: 'Font Awesome 5 Pro';
      font-weight: 900;
      font-size: 1rem;
      min-width: 1.5rem;
      max-width: 1.5rem;
    }
  }
  h3 span{
    font-family: 'Roboto' !important;
    font-weight: 700 !important;
    font-size: 1.125rem !important;
    margin-bottom: 1rem;

  }
}
}

.product-features, .product-info{
  .h6{
    font-family: 'Roboto' !important;
    font-weight: 700 !important;
    font-size: 1.125rem !important;

    margin-bottom: 1rem;
  }
}
.product-features{
      margin-left: 0rem;
      margin-top: 3rem;
      margin-bottom: 2rem;

}
.product-features > dl.data-sheet dd.value, .product-features > dl.data-sheet dt.name{
  margin-bottom: -1px;

}
.product-features > dl.data-sheet dt.name{
    border-top: 1px solid $gray-secondary;
    border-bottom: 1px solid $gray-secondary;

  font-weight: 300;
  margin-right: 0;
  margin-bottom: -1px;
  padding-left: 1.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.product-features > dl.data-sheet dd.value{
  border-top: 1px solid $gray-secondary;
  font-weight: 300;
  margin-right: 0;
  border-bottom: 1px solid $gray-secondary;
  margin-bottom: -1px;
  text-align: right;
  padding-right: 1.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;

}
.product-features > dl.data-sheet dd.value:nth-of-type(even), .product-features > dl.data-sheet dt.name:nth-of-type(even){
    background: transparent;

}
.gallery_big.swiper-container.swiper-container-fade{

  img{

    @media screen and (min-width: 1200px) and (max-width: 1400px){
      width: 40rem;

    }
  }
}
.product-gallery__wrapper{
  @media screen and (max-width: 991px){
    margin-bottom: 3rem;
  }
}
.product-price.h5.has-discount{

}
.h1.hidden-md-down{
  margin-top: 2.25rem;
}
[itemprop=description]{
  margin-top: 1.125rem;
}
#product-availability{
  margin-top: 3rem;
  display: flex;
  justify-content: flex-start;
  font-size: 1rem;
  font-family: 'Oswald';
  text-transform: uppercase;
  i{
    color: $orange;
    position: relative;
    top: 0.175rem;
    margin-right: 0.5rem;
    font-size: 1.125rem;
  }
}
.title_acc {
  margin-bottom: 2.75rem;
}
.question__wrapper{
  margin-top: 1.25rem;
  .question__button{
    @media screen and (max-width: 991px){
      display: flex;
      justify-content: center;
    }
  }
}
.bestseller-products .product-flags{
  top: 0;
}
.gallery_small{
  .big-image{
    img{
      @media screen and (min-width: 767px) and (max-width: 991px){
        width: 10em;
      }
    }
  }
}
