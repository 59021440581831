.sort-by{
  padding-left: 0;
  padding-right: 0;
}
.sort-by-row .dropdown-menu, .sort-by-row .products-sort-order {
  width: 20rem;
  max-width: 100%;
}
.width_100{
  width: 100%;
  float: none;
}
.justify__content{
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 767px){
    flex-direction: column;
  }
}
.products-sort-order{
 .dropdown{

 }
}
.sort-by-row{
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;

  @media screen and (max-width: 767px){
    flex-direction: column;
    justify-content: center;
    .pagination{
      justify-content: center;
      display: flex;
    }
    .products-sort-order{
      justify-content: center;
      display: flex;
      max-width: 100%;

    }
  }
  .products-sort-order{
    .select-title{
      position: relative;
      display: inline-flex;
      span.title-text{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 16rem;

        display: block;
        @media screen and (max-width: 767px){

          margin-right: 1rem;
        }
      }
      max-width: 20rem;
      .material-icons{
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;
      }
    }
  }
  .showing{
    display: none;
  }
  .filter-button{
    display: none;
  }
}
.sort-by-row{
  margin-left: 0;
}
@media screen and (max-width: 767px){
  .pagination{
    justify-content: center;
    display: flex;
  }
}
.sort-by{
  color: $black;
  margin-left: 0;
  text-align: left;
  min-width: 6.5rem;
}
.products-sort-order .select-title{
  box-shadow: none;
}
