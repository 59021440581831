#products,.featured-products,.product-accessories,.product-miniature,.bestseller-products, #packages  {
  body.noTouchscreen & a.thumbnail:hover,
  body.noTouchscreen & a.product-thumbnail:hover,
  body.noTouchscreen & a.thumbnail:focus,
  body.noTouchscreen & a.product-thumbnail:focus{
    opacity: 1;
  }
  #index & {
    .products{
      @media (max-width: 991px){
        flex-wrap: nowrap;
      }
      @media (min-width: 992px){
        flex-wrap: wrap;
      }
    }
  }
  .products{
    margin-top: 0.8125rem !important;
  }
  .swiper-slide {
    width: 20.6875em;
    margin-left: 0.375em;
    margin-right: 0.375em;
  }
  .swiper-container.swiper-container-free-mode {
    min-height: 24rem;
    @media (max-width: 767px){
      min-height: 24em;
      font-size: 0.75rem;
      .swiper-slide {
        width: 20.6875em;
        margin-left: 0.375em;
        margin-right: 0.375em;
      }
    }
  }
  .swiper-container:not(.swiper-container-free-mode) .products{
    // flex-wrap: wrap;
    // display: flex;
    // justify-content: flex-start;

  }
  .products:not(.swiper-wrapper){
    @media screen and (max-width: 991px){
      justify-content: center;
    }
  }
  .swiper-container:not(.swiper-container-free-mode){
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .product-thumbnail {
    display: block;
    width: 20.6875em;
    height: 20.6875em;
    img{
      width: 20.6875em;
      height: 20.6875em;
      object-fit: cover;
      border: 3px $black solid;
    }
  }
  .product-title a {
    color: white;
    font-size: 1rem;
    text-decoration: none;
    text-align: left;
    font-weight: 400;
  }
  .thumbnail-container {
    position: relative;
    margin-bottom: 1.563em;
    height: 26.5em;
    width: 20.6875em;
    @media (max-width: 767px){
      font-size: 0.75rem;
      width: 20.6875em;
      margin-bottom: 3.5em;
      position: relative;
      .product-description {
        bottom: -0.325em;
        max-width: 100%;
      }
      .product-thumbnail{
      }
    }
    box-shadow: none;

    background: transparent;
    .product-description {
      transition: background 0.5s ease;
      .regular-price, .price{

      }
    }
    .product-thumbnail {
      img{

        transition: border 0.5s ease;

      }
    }
    &:hover, &:focus {
     .highlighted-informations{
         bottom: 7.5rem !important;
     }
    }
    &:hover, &:focus {
      @media (min-width: 768px){
        .product-thumbnail {

          img{

            border: 3px $orange solid;

          }
        }
        .product-description {
          background: rgba($orange, 1);
          color: white;
          .regular-price, .price{
            color: white;
          }

          &::after{
            border-top: 0px solid transparent !important;
          }
        }
      }
    }
  }
  .products-section-title {
    text-align: left;
    margin-bottom: 1.5rem;
  }
  .bestseller__desc{
    text-align: left;
    font-size: 1.5rem;
    font-weight: 300;
    margin-bottom: 3.125rem;
  }
  .product-title {
    text-align: left;
    text-transform: capitalize;
    margin-top: 1rem;
    margin-bottom: 0.25rem;
  }
  .product-price-and-shipping {
    color: $gray-darker;
    font-weight: 700;
    text-align: center;
  }
  .variant-links {
    position: relative;
    text-align: center;
    width: 100%;
    top: -0.25em;
    padding-top: 0.1875rem;
    min-height: 2.5rem;
    background: white;

  }
  .highlighted-informations {
    bottom: 2rem;
    background: transparent;
    width: 100%;
    box-shadow: none;
    .quick-view{
      height: 3.25rem;
      width:  calc( 80% );
      font-size: 1rem;
      .text{
        position: relative;
        top: 0.5rem;
      }
    }


    .quick-view, .add-to-cart, .more_link{
      &:hover, &:focus{
        opacity: 1;
        color: white;
      }
    }

    .btn.black{
      background-color: $black;
    }
    .btn.white{
      background-color: transparent;
      border: 1px solid white;
    }
    .btn{
      color: white;
    }
  }
  .btn-primary{
    display: flex;
    align-items: center;
  }
  .product-description {
    position: absolute;
    z-index: 1;
    background: $black;
    width: 20.6875em;
    height: 4.375em;
  }
  img {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .product-miniature {
    margin: 0 0.625rem;
    margin-top: -0.8125rem;
    margin-bottom: -0.8125rem;
    // margin-bottom: 6rem;
    @media (max-width: 767px){
      margin: 0 0.125rem;

    }
    .discount {
      display: none;
    }
    .product-flags .new,
    .online-only,
    .on-sale,
    .discount-percentage,
    .discount-amount,
    .pack {
      display: block;
      position: absolute;
      left: -0.4375rem;
      padding: 0.3125rem 0.4375rem;
      color: white;
      background: $brand-primary;
      text-transform: uppercase;
      min-width: 3.125rem;
      min-height: 1.875rem;
      font-size: $base-font-size;
      font-weight: 600;
      // @include box-shadow-light;
      box-shadow: none;

      &.discount-percentage,
      &.discount-amount {
        z-index: 2;
        background: $orange;
      }
      &.on-sale{
        background: $orange;
        width: 100%;
        text-align: center;
        left:0;
        top:0;
      }
      &.online-only {
        font-size: $font-size-xs;
        margin-top: 13rem;
        margin-left: 8.688rem;
        &::before{
          content:"\E30A";
          font-family: 'Material Icons';
          vertical-align: middle;
          margin: 0.3125rem;
        }
      }
    }
  }
  .comments_note {
    text-align: center;
    color: $gray;
  }

  .regular-price, .price, .product-title, .product-title a, .product-price-and-shipping{
    text-align: left;
  }
  .product-price-and-shipping, .product-price-and-shipping span{
    font-family: 'Oswald';

  }
  .product-price-and-shipping{
    margin-left: 1rem;
  }
  .regular-price {
    color: $gray_presta;
    text-decoration: line-through;
    font-size: 1rem;
    font-family: 'Oswald';
  }
  .price{
    color: $orange;
    font-size: 1.25rem;
    font-family: 'Oswald';
  }
  .product-title{
    margin-left: 1rem;

    font-family: 'Roboto';
  }
  .product-title a, .product-title{
    font-weight: 700;
    font-family: 'Roboto';
    font-size: 1rem;
  }
  .product-description{
    height: 5rem;
    bottom: 0.875em;
  }
  .count {
    color: $gray;
    font-weight: 700;
    position: relative;
    bottom: 0.5rem;
  }
  .all-product-link {
    clear: both;
    color: $gray;
    font-weight: 700;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

}
.featured-products .product-description{

}
@include media-breakpoint-down(sm) {
  #products,.featured-products,.product-accessories  {
    .thumbnail-container {
      // @include box-shadow;

      box-shadow: none;
      .product-description{
        box-shadow: none;
      }
    }
  }
}
